/**
 * COMB
 * ====
 * the simple combination generator
 */

const FACT = [
    0, 1, 2, 6, 24, 120, 720, 5040, 40320, 362880, 3628800, 39916800, 479001600, 6227020800, 87178291200, 1307674368000,
    20922789888000, 355687428096000, 6402373705728000, 121645100408832000, 2432902008176640000, 51090942171709440000,
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    1124000727777607680000, 25852016738884976640000, 620448401733239439360000,
];

function fact(x) {
    return FACT[x];
}

/**
 * Given a set and the number of elements you want to have in a
 * combination, comb returns an iterator object, with the methods
 * `next` and `rewind`. If a callback function is given, it is
 * executed for each element right away.
 *
 * @constructor
 * @example
 *     var c = new Combinations([1,2,3], 2);
 *     c.next() // {done: false, value: [1,2]}
 *     c.next() // {done: false, value: [1,3]}
 *     c.next() // {done: false, value: [2,3]}
 *     c.next() // {done: true}
 *     c.rewind() // true
 *     c.next() // {done: false, value: [1,2]}
 *
 * @param {Array} set
 * @param {number} k
 * @param {function} cb
 * @return {{next: function, rewind: function, each: function, break, length: number}}
 */

export function Combinations(set, k, cb?) {
    const BREAK = {};
    let ida;
    const idx = [] as number[];
    const n = set.length;
    let first;

    // rewind the array of indices
    function rewind() {
        // idx is an array of consecutive integers, starting with zero
        // and has the length items we want to have from the set.
        // e.g. [0,1,3]
        for (let i = 0; i < k; i++) idx[i] = i;
        return (first = true);
    }

    /**
     * Execute function for each element until there are no more
     * elements or the combination.BREAK object is returned from the
     * callback. This method does NOT rewind beforehand, you have to
     * do that yourself.
     *
     * @param {function} cb
     * @return void
     */
    function each(cb) {
        let c;
        // perform callback for each element
        while ((c = next()) && !c.done) if (cb(c.value) === BREAK) break;
    }

    /**
     * return the next element
     *
     * @return {{done: boolean, value}}
     */
    function next() {
        // if its the first element, don't advance the index
        if (!first) {
            // start with the last index and see if we're at the end
            // of the set already. if so, check the previous index,
            // until we arrive at an index that is not at his last
            // possible position yet
            ida = k - 1;
            while (idx[ida] === n - k + ida) ida--;

            // advance this index, and place all following indices
            // after this one
            ++idx[ida];
            idx.slice(ida + 1).forEach(function (_, j) {
                idx[ida + j + 1] = idx[ida + j] + 1;
            });
        }

        // if the advancement results in the last index being out of
        // range, say that we're done
        if (isNaN(idx[0])) return { done: true };

        // otherwise set the flag for first element to false and
        // return the set elements at index positions
        return (
            (first = false),
            {
                done: false,
                value: idx.map(function (j) {
                    return set[j];
                }),
            }
        );
    }

    if (typeof cb === 'function') {
        (function () {
            let c: any;
            rewind();
            while ((c = next()) && !c.done) cb(c.value);
        })();
    }

    // rewind now and return the iterator object
    return (
        rewind(),
        {
            next: next,
            rewind: rewind,
            each: each,
            break: BREAK,
            length: fact(n) / (fact(k) * fact(n - k)),
        }
    );
}

import { Translation } from '../types/l10n';
import {
    Casino,
    Provider,
    ProviderLink,
    Licence,
    Subprovider,
    Country,
    Province,
    Studio,
    Game,
    BonusProvider,
    Type,
    GameLabel,
    RtpVersion,
    GameTheme,
    Feature,
    Currency,
    CasinoProviderMaintenance,
    OperatingLanguage,
    FullGame,
    GameComment,
    Category,
    GameImage,
} from '../types/casino';
import { createStores } from './utils';
import { VirtualSportsMaintenance } from 'types/casino';
import type { CasinoSegmentDetailed, CasinoSegmentFilterType, CasinoSegmentGameInfo } from '../types/casino-segments';

export const casino = createStores({
    casinos: [] as Casino[],
    providers: [] as Provider[],
    providersById: {} as { [id: number]: Provider },
    providersByName: {} as { [name: string]: Provider },
    rtpVersions: [] as RtpVersion[],
    rtpVersionsById: {} as { [id: number]: RtpVersion },
    providerLinks: [] as ProviderLink[],
    licences: [] as Licence[],
    licencesById: {} as { [id: number]: Licence },
    subproviders: [] as Subprovider[],
    subprovidersById: {} as { [id: number]: Subprovider },
    subprovidersByName: {} as { [name: string]: Subprovider },
    countries: [] as Country[],
    countriesByName: {} as { [name: string]: Country },
    provinces: [] as Province[],
    provincesByName: {} as { [name: string]: Province },
    studios: [] as Studio[],
    studiosById: {} as { [id: number]: Studio },
    studiosByName: {} as { [name: string]: Studio },
    bonusProviders: [] as BonusProvider[],
    gamesMappingPromise: undefined as Promise<Game[]> | undefined,
    types: [] as Type[],
    typesById: {} as { [key: string]: Type },
    currencies: [] as Currency[],
    currenciesByName: {} as { [currency: string]: Currency },
    gameLabels: [] as GameLabel[],
    gameLabelsById: {} as { [key: string]: GameLabel },
    gameLabelTranslations: {} as { [key: string]: Translation },
    isLoaded: false as boolean,
    gameThemes: {} as GameTheme[],
    gameThemesById: {} as { [key: string]: GameTheme },
    gameThemeTranslations: {} as { [key: string]: Translation },
    gameFeatures: [] as Feature[],
    gameFeaturesById: {} as { [key: string]: Feature },
    gameFeatureTranslations: {} as { [key: string]: Translation },
    virtualSportsMaintenances: [] as VirtualSportsMaintenance[],
    gameComments: [] as GameComment[],
    fullGame: {} as FullGame,
    casinoMaintenances: [] as CasinoProviderMaintenance[],
    operatingLanguages: [] as OperatingLanguage[],
    duplicate: false as boolean,
    games: [] as FullGame[],
    gameImage: '' as string,
    categories: [] as Category[],
    categoriesById: {} as { [id: number]: Category },
    gameImages: [] as GameImage[],
    gameImagesByGameId: {} as { [key: string]: GameImage },
    segments: createStores({
        segment: {} as CasinoSegmentDetailed,
        gameInfoByGroupId: new Map() as Map<number, CasinoSegmentGameInfo>,
        segmentFilterTypes: [] as CasinoSegmentFilterType[],
        isLoadingSegment: false,
        usedFilterTypeNames: [] as string[],
    }),
});

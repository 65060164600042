import { DatabaseOdds } from '@staycool/odds-types';
import { CmsType } from 'types/cms';
import { RetailEvent } from 'microservices/retail-middleware-socket';
import { MarketInfo } from 'microservices/sbgate';
import { initialBetSlipUserState } from 'services/retail';
import {
    BetSlipError,
    BetSlipReplayState,
    BetSlipUserState,
    ConTestPurchase,
    InquiryFilter,
    LastViewedMatch,
    MAStatus,
    MAVoidTicketIds,
    Otc,
    OtcSidebarViewMode,
    PaperParlayCard,
    ParlayCardBetslipMarketIdToOutcomeIds,
    ParlayCardWithMarket,
    RacingBetSlip,
    Shift,
    ShiftStatisticTab,
    TicketMAVoidRequest,
    ViewMode,
} from 'types/retail-otc';
import { createStores } from './utils';
import { ExtendedTerminal, TerminalStatus } from '@staycool/retail-types/terminal';
import { Settings } from '@staycool/retail-types/bet-shop-settings';
import { Notification } from '@staycool/retail-types/notification';
import { Settings as RetailSettings } from '@staycool/retail-types/settings';
import { TicketResponse } from '@staycool/retail-types/ticket';
import { TeaserPayouts } from 'microservices/sports/payout';
import { ItemToPurchase } from '@staycool/retail-types/otc';
import { ExtendedShift } from '@staycool/retail-types/shift';
import { FOParlayCardMarket } from '@staycool/sports-types/parlay-card';
import { ExtendedVoucher } from '@staycool/retail-types/voucher';
import { W2gEventDetails } from 'microservices/sports/w-2-g';
import { LoyaltyResponse } from '@staycool/retail-types/loyalty';
import { initialHorseRacingBetSlipState } from 'services/retail/horse-racing';
import { TodaysTrack } from '@staycool/lvdc-types/dist/features/track/types';

export const retail = createStores({
    otc: { status: TerminalStatus.NotInitialized, hash: 'NO HASH PRESENT' } as Otc,
    terminals: [] as ExtendedTerminal[],
    isMiddlewareActive: false,
    shift: undefined as Shift | undefined,
    errorNotifications: [] as Notification[],
    event: undefined as RetailEvent | undefined,
    betSlipMarketIdToOutcomeId: {} as {
        [marketId: number]: number;
    },
    marketInfoById: {} as {
        [marketId: string]: MarketInfo;
    },
    oddsByOutcomeId: {} as Record<number, DatabaseOdds>,
    betSlipUserState: initialBetSlipUserState as BetSlipUserState,
    placeBetViewMode: ViewMode.LastViewed as ViewMode,
    lastVisitedModeList: [] as ViewMode[],
    selectedMatch: {} as LastViewedMatch,
    searchTerm: '',
    otcSidebarViewMode: OtcSidebarViewMode.Navigation as OtcSidebarViewMode,
    marketIdForStakeInput: undefined as string | undefined,
    betSlipMarketIds: [] as string[],
    betSlipErrorsByMarketId: {} as {
        [marketId: string]: BetSlipError[];
    },
    otcNavigateClick: 1,
    parlayCard: createStores({
        parlayCard: {} as ParlayCardWithMarket,
        marketInfo: {} as Record<number, FOParlayCardMarket>,
        betSlipMarketIdToOutcomeIds: {} as ParlayCardBetslipMarketIdToOutcomeIds,
        oddsByOutcomeId: {} as Record<number, DatabaseOdds>,
        paperParlayCard: {} as PaperParlayCard,
        twoSelectionMarkets: [] as FOParlayCardMarket[],
    }),
    loyalty: {} as LoyaltyResponse,
    betShopTerminals: [] as ExtendedTerminal[],
    betShopOtcSettings: {} as Settings,
    cmsListFilter: Object.values(CmsType),
    retailSettings: {} as RetailSettings['settings'],
    otcInquiryFilter: {} as InquiryFilter,
    unpaidTickets: [] as ItemToPurchase[],
    isTeasersAvailable: false,
    teaserPayouts: {} as TeaserPayouts,
    teaserSelectedPoint: undefined as number | undefined,
    teaserMainLineErrorMarketIds: [] as number[],
    teaserInvalidMainLineErrorMarketIds: [] as number[],
    teaserStatusDisabledMarketIds: [] as number[],
    shiftStatisticActiveTab: '' as ShiftStatisticTab,
    ongoingShifts: [] as ExtendedShift[],
    selectedOngoingShift: undefined as ExtendedShift | undefined,
    betSlipReplayState: {} as BetSlipReplayState,
    horseRacingBetSlipReplayState: {} as RacingBetSlip,
    horseRacingBetSlip: initialHorseRacingBetSlipState,
    retailModalBringYourDevice: undefined as RetailEvent | undefined,
    isByodOrReplay: false,
    retailMaTicketId: '',
    retailVoidTicket: undefined as MAVoidTicketIds | undefined,
    maTicketPlacementStatus: 'Initial' as MAStatus,
    maVoidStatus: 'Initial' as MAStatus,
    itemsToPurchase: [] as ItemToPurchase[],
    ticketToRedeem: undefined as TicketResponse | undefined,
    voucherToRedeem: undefined as ExtendedVoucher | undefined,
    entriesToPurchase: [] as ConTestPurchase[],
    w2GEvent: undefined as
        | (W2gEventDetails & {
              retail_ticket_ids: string[];
          })
        | undefined,
    cart: createStores({ cashFromClient: 0, chipsFromClient: 0, changeBack: 0, isChangeBackModalIsOpen: false }),
    raceTracks: [] as TodaysTrack[],
    ticketMaVoidRequest: undefined as TicketMAVoidRequest | undefined,
});

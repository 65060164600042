import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import Wrapper from './styles';
import 'react-simple-keyboard/build/css/index.css';
import { RetailKeyboardLayout } from 'types/retail-otc';

interface Props {
    setValue: any;
    width?: number | string;
    layout?: any;
    inititalLayout?: RetailKeyboardLayout;
    display?: any;
    isFullKeyboard?: boolean;
    onEnter?: () => void;
    onKeyPress?: (button: string) => void;
    onLayoutChange?: (layout: RetailKeyboardLayout) => void;
}

export function UiKeyboard({
    setValue,
    layout,
    inititalLayout = 'default',
    width = 800,
    isFullKeyboard = false,
    onEnter,
    display = {
        '{clear}': 'CLR',
        '{shift}': 'shift',
        '{space}': 'space',
        '{bksp}': isFullKeyboard ? '⌫' : 'backspace',
    },
    onKeyPress,
    onLayoutChange,
}: Props) {
    const [layoutName, setLayoutName] = useState<RetailKeyboardLayout>(inititalLayout);

    function handleLayoutNameChange(name: RetailKeyboardLayout) {
        setLayoutName(name);
        if (onLayoutChange) {
            setValue('');
            onLayoutChange(name);
        }
    }

    function handleKeyPress(button: string) {
        if (button === '{shift}' || button === '{lock}') {
            handleLayoutNameChange(layoutName === 'default' ? 'shift' : 'default');
        } else if (button === '{123}' || button === '{ABC}') {
            handleLayoutNameChange(button === '{123}' ? 'numpad' : 'default');
        } else if (button === '{bksp}') {
            setValue(value => value.slice(0, -1));
        } else if (button === '{space}') {
            setValue(value => value + ' ');
        } else if (button === '{enter}') {
            if (onEnter) {
                onEnter();
            }
        } else if (button === '{clear}') {
            setValue('');
        } else if (button === '.') {
            setValue(value => {
                const lastCharacter = value.slice(-1);
                if (value.includes('.')) {
                    return value;
                } else if (lastCharacter === '') {
                    return `0${button}`;
                } else if (!isNaN(lastCharacter)) {
                    return `${value}${button}`;
                }
            });
        } else if (button === '{confirm}') {
            setValue(value => value);
        } else {
            setValue(value => `${value}${button}`);
        }
        if (onKeyPress) {
            onKeyPress(button);
        }
    }

    return (
        <Wrapper
            width={width}
            isFullKeyboard={layoutName === 'default' ? isFullKeyboard : false}
            isNumpad={layoutName === 'numpad'}
        >
            <Keyboard onKeyPress={handleKeyPress} layoutName={layoutName} layout={layout} display={display} />
        </Wrapper>
    );
}

import { color } from 'style/variables';
import styled from 'styled-components';

export default styled.div`
    color: white;
    width: ${props => (props.isNumpad ? '40%' : props.width)};

    @media (min-width: 1441px) {
        font-size: 1.5rem;
    }

    .hg-button {
        background-color: ${color.gray300};
        flex-grow: ${props => (props.isFullKeyboard ? '0' : '1')};
        width: ${props => (props.isFullKeyboard ? '60px !important' : '')};
        height: ${props => (props.isNumpad ? '54px !important' : '')};

        &.hg-activeButton {
            background-color: ${color.gray500};
        }

        &.hg-button-clear {
            background-color: ${({ isFullKeyboard }) => (isFullKeyboard ? `${color.gray500}` : `${color.gray600}`)};
            width: ${props => (props.isFullKeyboard ? '60px !important' : props.isNumpad ? '20px' : '')};

            &.hg-activeButton {
                background-color: ${({ isFullKeyboard }) =>
                    isFullKeyboard ? `${color.gray600} !important` : `${color.gray700} !important`};
            }

            @media (min-width: 1441px) {
                width: ${props => (props.isFullKeyboard ? '170px !important' : '')};
            }
        }
        &.hg-button-confirm {
            background-color: ${color.green500};
        }

        &.hg-button-bksp {
            background-color: ${color.gray600};
            width: ${props => (props.isFullKeyboard ? '60px !important' : props.isNumpad ? '20px' : '')};

            &.hg-activeButton {
                background-color: ${color.gray700} !important;
            }

            @media (min-width: 1441px) {
                width: ${props => (props.isFullKeyboard ? '170px !important' : '')};
            }
        }

        &.hg-button-space {
            width: ${props => (props.isFullKeyboard ? '640px !important' : '')};

            @media (min-width: 1441px) {
                width: ${props => (props.isFullKeyboard ? '1500px !important' : '')};
            }
        }

        &.hg-button-123,
        &.hg-button-ABC {
            background-color: ${color.gray600};
        }

        &.hg-button-ABC {
            width: ${({ isNumpad }) => (isNumpad ? '20px' : '')};
        }

        @media (min-width: 1441px) {
            width: ${props => (props.isFullKeyboard ? '150px !important' : '')};
            height: ${props => (props.isFullKeyboard ? '60px !important' : props.isNumpad ? '80px !important' : '')};
        }
    }

    .hg-rows > div:nth-child(1) {
        margin-bottom: ${props => (props.isFullKeyboard ? '10px' : '')};

        @media (min-width: 1441px) {
            margin-bottom: ${props => (props.isFullKeyboard ? '20px' : '')};
        }
    }

    .hg-rows > div:nth-child(1) {
        & .hg-button {
            background-color: ${({ isFullKeyboard }) => (isFullKeyboard ? `${color.gray600}` : '')};
        }

        & .hg-activeButton {
            background-color: ${({ isFullKeyboard }) => (isFullKeyboard ? `${color.gray700} !important` : '')};
        }
    }

    .hg-rows > div:nth-child(4) {
        @media (min-width: 1441px) {
            padding-right: ${props => (props.isFullKeyboard ? '20px' : '')};
            padding-left: ${props => (props.isFullKeyboard ? '20px' : '')};
        }
    }

    .hg-row {
        justify-content: ${props => (props.isFullKeyboard ? 'center' : '')};
    }

    .simple-keyboard {
        background-color: transparent;
    }
`;

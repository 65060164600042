import { OddsFormat } from '@staycool/bets-types';
import { LegacyLanguage } from '@staycool/localization';
import { Country, Licence, Market } from '@staycool/location';
import { getServiceUrl, httpGet, httpPost } from 'services/api';
import { EVerified, Gender, PopulationRegistryState, UserProvince } from 'types/users';
import { GroupedPaymentMethod } from './payments2';

export const getUrl = url => getServiceUrl('users-proxy', url);

export function getUserById(userId: string, isRetail = false) {
    const url = getUrl(`users/${userId}${isRetail ? '?is_retail=true' : ''}`);
    return httpGet<User>(url);
}

export function getUserByIdAdmin(userId: string, query) {
    const url = getUrl(`users/admin/${userId}`);
    return httpGet<DetailedUser>(url, query);
}

export function getUsers(filter: Record<string, any>) {
    const url = getUrl(`users/list`);
    return httpPost<User[]>(url, filter);
}

export function getRetailUsers(filter: Record<string, any>, terminalId?: number) {
    const url = getUrl(`users/list?is_retail=true${terminalId ? `&terminal_id=${terminalId}` : ''}`);
    return httpPost<User[]>(url, filter);
}

export interface UsersData {
    hasNext: boolean;
    result: User[];
}

export function getUsersListAdmin(params: Record<string, any>) {
    const url = getUrl(`users/admin/list`);
    return httpGet<{ result: DetailedUser[]; hasNext: boolean }>(url, params);
}

export interface User {
    id: string;
    alias: string;
    avatar?: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    email: string;
    phoneNumber?: string;
    phonePrefix?: string;
    gender: Gender;
    language: LegacyLanguage;
    province: UserProvince | null;
    city: string;
    country: Country;
    createdAt: Date;
    isClosed: boolean;
    isSportsBonusAbuser: boolean;
    isTest: boolean;
    groupedMethods: GroupedPaymentMethod[];
    loyaltyId?: string | null;
}

export interface DetailedUser extends User {
    address: string;
    birthPlace: string | null;
    casinoClosedInRegulator: string | null;
    closeReasonId: number | null;
    eVerified: EVerified;
    industryId: number;
    isCasinoBonusAbuser: boolean;
    isCasinoClosed: boolean;
    isEmailSubscription: boolean;
    isEmailVerified: boolean;
    isEstMobileId: boolean;
    isInDangerZone: boolean;
    isMailSubscription: boolean;
    isManualVerified: boolean;
    isPhoneSubscription: boolean;
    isPokerClosed: boolean;
    isRgMailSent: boolean;
    isShowInLeaderboard: boolean;
    isSmsSubscription: boolean;
    isSmsVerified: boolean;
    isSportsbookClosed: boolean;
    isVerified: boolean;
    isVirtualSportsClosed: boolean;
    jobTitle: string;
    lastCheckInRegulator: Date;
    lastWagerDate: Date;
    market?: Market;
    licence?: Licence;
    nationality: string | null;
    oddsFormat: OddsFormat;
    personalId: string;
    populationRegistryState: PopulationRegistryState;
    registrationSource: string | null;
    rgCallMade: boolean;
    source: string;
    sportsbookClosedInRegulator: string | null;
    zip: string;
    externalId?: string;
    salutation?: string;
    loyaltyId?: string;
    sourceOfFunds?: string;
}

export interface RetailUserListFilter {
    firstName: string;
    lastName: string;
    alias: string;
    orderBy: string;
}

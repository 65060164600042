import { Market } from '@staycool/location';
import { getServiceUrl, httpDelete, httpGet, httpPatch, httpPost, httpPut } from 'services/api';
import { userCommentLimit, userHistoryLimit } from 'services/user/user';
import type { Filter } from 'types/complex-filter';
import { KycMasterMailSections } from 'types/l10n';
import {
    ClosureAppeal,
    ClosureAppealQuestion,
    ClosureAppealState,
    Countries,
    FormSubmission,
    LimitedUserData,
    LoyaltyUserData,
    ReopenRequest,
    SignupProperty,
    TermsAndConditionsHistory,
    UserCloseReason,
    UserComment,
    UserCommentsParams,
    UserGroup,
    UserHistory,
    UserHistoryParams,
    UserIndustry,
    UserLastLogin,
    UserLogsResponse,
    UserLoyaltyData,
    UserMetaData,
    UserProductStatus,
    UserSettings,
    UserUpdateRequest,
    UserUpdateResponse,
    UsersCountries,
    UsersLoginHistory,
    UsersSourceOfWealth,
} from 'types/users';

export const getUrl = url => getServiceUrl('users', url);

export function addClosureAppealQuestion(
    params: Pick<ClosureAppealQuestion, 'closeReasonId' | 'order' | 'translationKey'>,
) {
    const url = getUrl('appeal/questions');
    return httpPost<void>(url, params);
}

export function appealComment(id: string, comment: string | undefined) {
    const url = getUrl('appeal/comment');
    return httpPost<void>(url, { id, comment });
}

export function decideOnAppeal(params: { id: string; state: ClosureAppealState; reopenAt?: Date }) {
    const url = getUrl('appeal/decide');
    return httpPost<void>(url, params);
}

export async function deleteClosureAppealQuestion(id: number) {
    const url = getUrl(`appeal/questions/${id}`);
    return httpDelete<void>(url);
}

export function getClosureAppealQuestions(closeReasonId?: number) {
    const url = getUrl('appeal/questions/list');
    return httpGet<ClosureAppealQuestion[]>(url, { closeReasonId });
}

export function getClosureAppeals(filters) {
    const url = getUrl('appeal/filter');
    return httpGet<{ items: ClosureAppeal[]; total: number }>(url, { filters: JSON.stringify(filters) });
}

export function saveClosureAppealQuestions(questions: Partial<ClosureAppealQuestion>[]) {
    const url = getUrl('appeal/questions/update');
    return httpPut<void>(url, questions);
}

export function sendEmail(
    params: { description?: string; userId: string; type: string } & Partial<KycMasterMailSections>,
) {
    const url = getUrl('kyc-email/');
    return httpPost(url, params);
}

export function updateUser(userId: string, update: UserUpdateRequest) {
    const url = getUrl(`users/${userId}`);
    return httpPatch<UserUpdateResponse>(url, update);
}

export async function getLimitedUserData(payload: { aliases?: string[]; userIds?: string[] }) {
    const url = getUrl('users/admin/minimal');
    return httpPost<LimitedUserData[]>(url, payload);
}

export function updateUserComment(commentId: number, comment: UserComment) {
    const url = getUrl(`user-comments/${commentId}`);
    return httpPut<UserComment>(url, comment);
}

export function addNewUserComment(comment: Partial<UserComment>) {
    const url = getUrl('user-comments');
    return httpPost<UserComment>(url, comment);
}

export function getUserLastLogin(isFullResponse: true, userId: string): Promise<Record<string, UserLastLogin>>;
export function getUserLastLogin(isFullResponse: false, userId: string): Promise<Record<string, string>>;
export function getUserLastLogin(isFullResponse: boolean, userId: string) {
    const url = getUrl('user-login-history/admin/last-login');
    return httpGet<Record<string, UserLastLogin | string>>(url, { isFullResponse, userIds: userId });
}

export function getCloseReasons() {
    const url = getUrl(`account-close-reasons`);
    return httpGet<UserCloseReason[]>(url);
}

export function getIndustries() {
    const url = getUrl(`industries`);
    return httpGet<UserIndustry[]>(url);
}

export function getUserGroups() {
    const url = getUrl(`user-group`);
    return httpGet<UserGroup[]>(url);
}

export function getUserSettings(userId: string) {
    const url = getUrl(`user-settings/admin/${userId}`);
    return httpGet<UserSettings>(url);
}

export function getUserProductStatus(userId: string) {
    const url = getUrl(`user-product-status/admin/${userId}`);
    return httpGet<UserProductStatus>(url);
}

export function updateProductStatus(userId: string, update: Partial<UserProductStatus>) {
    const url = getUrl(`user-product-status/admin/${userId}`);
    return httpPut<UserProductStatus>(url, update);
}

export function updateUserSettings(update: Partial<UserSettings>) {
    const url = getUrl(`user-settings/admin/${update.userId}/`);
    return httpPut<UserSettings>(url, update);
}

export function updateSportBonusAbuser(userId: string, isSportBonusAbuser: boolean) {
    const url = getUrl(`users-sb/${userId}/`);
    return httpPut(url, { sportsBonusAbuser: isSportBonusAbuser });
}

export function getUserLogsByParams({
    where,
    page = 1,
    whereIn,
    distinctOn,
    orderBy,
    pageSize = userHistoryLimit,
}: UserHistoryParams) {
    const url = getUrl('user-histories/admin/filter');
    const filters = {
        whereIn,
        where,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        distinctOn,
        orderBy,
    };
    return httpGet<UserLogsResponse>(url, {
        filters: JSON.stringify(filters),
    });
}

export function updateUserLogs(payload: Partial<UserHistory>) {
    const url = getUrl(`user-histories/admin/log`);
    return httpPost(url, payload);
}

export async function filterUserComments({
    userId,
    type,
    page = 1,
    pageSize = userCommentLimit,
    orderBy,
    where,
}: UserCommentsParams) {
    const url = getUrl('user-comments/admin/filter');
    const pageSanitized = Math.max(0, Number(page - 1)) || 0;
    const offset = pageSanitized * pageSize;
    const filter: Filter = {
        where: { ...where, userId },
        whereIn: [{ field: 'type', values: type === 'comment' ? [type, 'system_email'] : [type] }],
        offset,
        limit: pageSize,
        orderBy,
    };
    return httpGet<{ items: UserComment[]; total: number }>(url, { filters: JSON.stringify(filter) });
}

export function getCountries() {
    const url = getUrl('countries');
    return httpGet<Countries>(url);
}

export function triggerNewSourceOfWealthForm(userId: string, state: string) {
    const url = getUrl('source-of-wealth/admin/state');
    return httpPost(url, { userId, state });
}

export async function getLastSourceOfWealthFormSubmission(userId: string) {
    const url = getUrl('source-of-wealth/admin');
    const filters = {
        where: { formKey: 'SOURCE_OF_WEALTH', userId },
        limit: 1,
    };
    const {
        items: [lastSubmission],
    } = await httpGet<{ items: FormSubmission[]; total: number }>(url, {
        filters: JSON.stringify(filters),
    });
    return lastSubmission;
}

export function deleteSourceOfWealthFormSubmission(id: number) {
    const url = getUrl(`source-of-wealth/admin/${id}`);
    return httpDelete(url);
}

export function triggerPopulationRegistryCheck(userId: string) {
    const url = getUrl(`population-registry-check/trigger/${userId}`);
    return httpPost(url);
}

export function userAddressVerification(userId: string) {
    const url = getUrl('user-address-verification');
    return httpPost(url, { userId });
}

export function getUsersLoginHistory(filters) {
    const url = getUrl('user-login-history/admin/filter');
    return httpGet<{ items: UsersLoginHistory[]; total: number }>(url, filters);
}

export function updateUserMeta(userId: string, update: Partial<UserMetaData>) {
    const url = getUrl('user-meta');
    return httpPost<UserMetaData>(url, update);
}

export function updateUserLoyalty(userId: string, update: Partial<UserLoyaltyData>) {
    const url = getUrl('user-loyalty');
    return httpPost<UserLoyaltyData>(url, update);
}

export function getUserByLoyaltyId(loyaltyId: string) {
    const url = getUrl(`user-loyalty/${loyaltyId}`);
    return httpGet<UserLoyaltyData>(url);
}

export function getUserDataByLoyaltyIds(loyaltyIds: string[]) {
    const url = getUrl(`user-loyalty/list`);
    return httpGet<LoyaltyUserData[]>(url, { ids: loyaltyIds.join(',') });
}

export async function getUsersCountry(userIds: string[]) {
    const url = getUrl('users/get-country-batch/');
    return await httpPost<UsersCountries[]>(url, { userIds });
}

export function getTermsAndConditionsHistory(filter) {
    const url = getUrl('terms-and-conditions/history');
    return httpGet<{ items: TermsAndConditionsHistory[]; total: number }>(url, { filter: JSON.stringify(filter) });
}

export function revertTermsAndConditions(id: TermsAndConditionsHistory['id']) {
    const url = getUrl(`terms-and-conditions/revert/${id}`);
    return httpPost<TermsAndConditionsHistory>(url);
}

export function triggerTermsAndConditions(market: Market) {
    const url = getUrl(`terms-and-conditions/trigger/${market}`);
    return httpPost<TermsAndConditionsHistory>(url);
}

export async function getSourceOfWealthForms(params?) {
    const url = getUrl(`source-of-wealth/admin`);
    return httpGet<{ items: UsersSourceOfWealth[]; total: number }>(url, params);
}

export function updateState(submissionId: number, state: string) {
    const url = getUrl('source-of-wealth/admin/state');
    return httpPost(url, { submissionId, state });
}

export function getSelfExcludedInRegulator(userId: string) {
    const url = getUrl(`self-excluded-in-regulator/${userId}`);
    return httpGet<{
        casinoClosedInRegulator: string;
        sportsbookClosedInRegulator: string;
        lastCheckInRegulator: string;
    }>(url);
}

export function getSignupProperties() {
    const url = getUrl('signup-property/list');
    return httpGet<SignupProperty[]>(url);
}

export function getUserConsent(userId: string) {
    const url = getUrl(`/consent/${userId}`);
    return httpGet<{ userId: string; type: string; isAccepted: boolean }[]>(url);
}

export function getReopenRequest(userId: string) {
    const url = getUrl(`reopen-request/${userId}`);
    return httpGet<ReopenRequest>(url);
}

export function createReopenRequest(userId: string, reopenAt: string) {
    const url = getUrl(`reopen-request/${userId}`);
    return httpPost<ReopenRequest>(url, { reopenAt });
}

export function deleteReopenRequest(userId: string) {
    const url = getUrl(`reopen-request/${userId}`);
    return httpDelete<number>(url);
}

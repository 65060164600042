import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import uniq from 'lodash/uniq';
import times from 'lodash/times';

export const getCombinations = (arr: number[][], n: number) => {
    const arrClone = [...arr];
    let i;
    let j;
    let k;
    let element;
    let childCombinations: number[][];
    const result: number[][] = [];
    if (n === 1) {
        for (i = 0; i < arrClone.length; i++) {
            for (j = 0; j < arrClone[i].length; j++) {
                result.push([arrClone[i][j]]);
            }
        }
        return result;
    } else {
        for (i = 0; i < arrClone.length; i++) {
            element = arrClone.shift();
            for (j = 0; j < element.length; j++) {
                childCombinations = getCombinations([...arrClone], n - 1);
                for (k = 0; k < childCombinations.length; k++) {
                    result.push([element[j], ...childCombinations[k]]);
                }
            }
        }
        return result;
    }
};

export const getBoxedBetsCount = (runnerMaps: number[][], isUniq: boolean = false) => {
    let combinations: number[][] = [];
    const indexCombinations = getCombinations(
        times(runnerMaps.length, () => range(0, runnerMaps.length)),
        runnerMaps.length,
    ).filter(item => uniq(item).length === runnerMaps.length);
    indexCombinations.forEach(runnerMapIndexes => {
        const clonedRunnerMaps = Array(runnerMaps.length).fill([]);
        runnerMapIndexes.forEach((idx, index) => (clonedRunnerMaps[index] = [...runnerMaps[idx]]));
        const betCombinations = getCombinations([...clonedRunnerMaps], runnerMaps.length);
        combinations = [...combinations, ...betCombinations];
    });
    const combinationsMap = new Map<string, number[]>();
    combinations.forEach(combination => {
        if (isUniq) {
            combination = orderBy(combination, x => x);
        }
        const key = combination.join(',');
        if (!combinationsMap.has(key) && uniq(combination).length === runnerMaps.length) {
            combinationsMap.set(key, combination);
        }
    });
    return Array.from(combinationsMap.keys()).length;
};

export function factorial(num: number) {
    let returnValue = 1;
    for (let i = 2; i <= num; i++) {
        returnValue = returnValue * i;
    }
    return returnValue;
}

import React from 'react';
import { Layout, notification } from 'antd';
import { useRoutes } from 'services/router';
import { getRootRoutes } from 'pages';
import { Navigator } from './components/navigator/Navigator';
import Header from './components/header/Header';
import { SportsbookCategoryAndMatchSearchModal } from 'components/sportsbook/category-and-match-search/modal/SportsbookCategoryAndMatchSearchModal';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { ROLES, useKeycloak } from 'services/keycloak';
import { SportsbookTreeNavigator } from 'components/sportsbook/tree-navigator/SportsbookTreeNavigator';
import { useFavicon } from 'hooks/useFavicon';
interface Props {
    isRetailOtcRoute: boolean;
}

const { SPORTS } = ROLES.SPORTS;

export function App({ isRetailOtcRoute }: Props) {
    const keycloak = useKeycloak();
    const isAvailableSportsbookCategoryTree = keycloak.hasAccessForPermissions([SPORTS]);
    useFavicon();
    const routes = useRoutes(getRootRoutes());
    notification.config({
        placement: isRetailOtcRoute ? 'topRight' : 'bottomRight',
    });

    useDocumentTitle(true);
    if (isRetailOtcRoute) {
        return (
            <Layout.Content>
                <Navigator routes={routes} />
            </Layout.Content>
        );
    }

    return (
        <Layout>
            {isAvailableSportsbookCategoryTree && <SportsbookTreeNavigator />}
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content>
                    <Navigator routes={routes} />
                </Layout.Content>
            </Layout>
            <SportsbookCategoryAndMatchSearchModal />
        </Layout>
    );
}

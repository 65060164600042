import { Licence } from '@staycool/location';
import { Country, Market } from '@staycool/location';
import { Setting } from 'microservices/refer-a-friend';
import { RetailTerminalConfig } from 'microservices/retail-proxy';
import { DetailedUser } from 'microservices/users-proxy';
import { KeycloakUser } from 'services/keycloak';
import { getMedia } from 'services/media';
import { IS_NOTIFICATION_ENABLED_KEY } from 'services/notification';
import { BoUser } from 'types/auth-bo';
import { UserComment } from 'types/users';
import { ClientName } from '../types/client';
import { casino } from './casino';
import { features } from './features';
import { marketing } from './marketing';
import { payments } from './payments';
import { wallet } from './wallet';
import { poker } from './poker';
import { providersBalances } from './providers-balances';
import { racebook } from './racebook';
import { reconciliation } from './reconciliation';
import { retail } from './retail';
import { services } from './services';
import { sportsbook } from './sportsbook';
import { createStores, getStoreValue, useStore } from './utils';
import { LANGUAGE } from '../services/language';
import { Currency } from '../services/wallet';

export { getStoreValue, useStore };

interface Environment {
    AVAILABLE_LICENSES: Licence[];
    CLIENT_NAME: ClientName;
    BASE_CURRENCY: Currency;
    CURRENCIES: Currency[];
    LANGUAGES: LANGUAGE[];
    COUNTRIES: Country[];
    ALLOWED_EMPLOYEE_EMAIL_DOMAINS: string[];
    ALLOWED_EMAIL_PROFILE_SENDER_DOMAINS: string[];
    KEYCLOAK_AUTH_URL: string;
    KEYCLOAK_AUTH_REALM: string;
    SENTRY_DSN: string;
    ENVIRONMENT: 'dev' | 'stage' | 'qa' | 'prod';
    ROOT_URL: string;
    OLD_BACKOFFICE_URL: string;
    FRONTOFFICE_URL: string;
    NEW_BACKOFFICE_URL: string;
    TRANSLATIONS_FALLBACK_LANGUAGE: string;
    IS_SINGLE_STEP_RESULTING_DISABLED?: 'true';
    CMS_BLOG_PREVIEW: string;
    IMAGES_HOST: string;
    TERMINAL_INITIALIZATION_URL: string;
    CRM_MESSAGE_PREVIEW: string;
    AVAILABLE_MARKETS: Market[];
    QUALIFIED_NAME: string;
    MAX_IMAGE_UPLOAD_SIZE: number;
    IS_PROVIDER_MAPPINGS_MODAL_ENABLED: string;
    FEATURE_ENABLED_CASINO_RACE_BUY_IN: boolean;
    FEATURE_ENABLED_PAYOUT_ROUND_UP?: string;
}

export const store = createStores({
    applicationType: undefined,
    authzPermissions: [] as any[],
    boUser: {} as KeycloakUser,
    boUsers: [] as BoUser[],
    boUsersById: {} as Record<string, BoUser>,
    development: {
        status: 'ok',
        errors: {},
    },
    deviceType: undefined,
    employees: [],
    environment: {} as Environment,
    ipCountry: undefined,
    isAuthenticated: false,
    isBootstrapped: false,
    isFilterInputActive: false,
    isNotificationEnabled:
        localStorage.getItem(IS_NOTIFICATION_ENABLED_KEY) === undefined ||
        localStorage.getItem(IS_NOTIFICATION_ENABLED_KEY) === 'true',
    language: undefined,
    lint: {
        totalErrors: 0,
        prettierErrors: [],
        eslintReport: {},
    },
    media: getMedia(),
    payments,
    rafSettings: [] as Setting[],
    router: {
        location: window.location as any,
        action: null as any,
    },
    secondsPassedSinceApplicationStart: 0,
    sentryVersion: undefined,
    tabElection: undefined as any,
    tokenAuth: undefined,
    tokenCbAuth: undefined as string | undefined,
    userCommentsById: {} as Record<string, UserComment[]>,
    usersById: {} as Record<string, DetailedUser>,
    sportsbook,
    racebook,
    features,
    retail,
    marketing,
    providersBalances,
    reconciliation,
    retailTerminalConfig: {
        timezone: '',
        networkSettings: {
            ip: '',
            dns: '',
            mask: '',
            gateway: '',
            isDhcp: false,
        },
    } as RetailTerminalConfig,
    marketsWithOpenSettings: [] as number[],
    services,
    casino,
    poker,
    categoryIconNames: {} as Record<number, string>,
    wallet,
});

declare global {
    interface Window {
        stores: any;
    }
}

window.stores = store;

import { BetType } from '@staycool/lvdc-types/bets';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import dayjs from 'services/dayjs';
import { isDevelopment } from 'services/util';
import { ticketHandlerByBetType } from './handlers';
import { notification } from 'antd';
import { getStoreValue } from '../../../stores/utils';
import { store } from '../../../stores/store';
import { initialHorseRacingBetSlipState } from '../horse-racing';

export const DATE = isDevelopment()
    ? dayjs().subtract(1, 'day').tz('America/Los_Angeles').format('YYYY-MM-DD')
    : dayjs().tz('America/Los_Angeles').format('YYYY-MM-DD');

interface BetTypeSettings {
    selectionsCount: number;
    isMultiRace?: boolean;
    bet_pools_count?: number;
    isKeyType?: boolean;
}

export const settingsByBetType: Partial<Record<BetType, BetTypeSettings>> = {
    WIN: { selectionsCount: 1 },
    PLC: { selectionsCount: 1 },
    SHW: { selectionsCount: 1 },
    WP: { selectionsCount: 1, bet_pools_count: 2 },
    WPS: { selectionsCount: 1, bet_pools_count: 3 },
    WS: { selectionsCount: 1, bet_pools_count: 2 },
    PS: { selectionsCount: 1, bet_pools_count: 2 },
    EXA: { selectionsCount: 2 },
    EBX: { selectionsCount: 1 },
    QNL: { selectionsCount: 2 },
    QBX: { selectionsCount: 1 },
    TRI: { selectionsCount: 3 },
    TBX: { selectionsCount: 1 },
    DBL: { selectionsCount: 2, isMultiRace: true },
    PK3: { selectionsCount: 3, isMultiRace: true },
    PK4: { selectionsCount: 4, isMultiRace: true },
    PK5: { selectionsCount: 5, isMultiRace: true },
    PK6: { selectionsCount: 6, isMultiRace: true },
    PK7: { selectionsCount: 7, isMultiRace: true },
    PK8: { selectionsCount: 8, isMultiRace: true },
    PK9: { selectionsCount: 9, isMultiRace: true },
    P10: { selectionsCount: 10, isMultiRace: true },
    SFC: { selectionsCount: 4 },
    SFX: { selectionsCount: 1 },
    TRK: { selectionsCount: 2, isKeyType: true },
    SFK: { selectionsCount: 2, isKeyType: true },
    E5K: { selectionsCount: 2 },
    GSL: { selectionsCount: 4, isMultiRace: true },
    E05: { selectionsCount: 5 },
    E5X: { selectionsCount: 1, isKeyType: true },
};

const hasDuplicates = (array: number[]) => new Set(array).size !== array.length;

export const getTicketBetsCombinations = (betType: string, runnerMaps: number[][]) => {
    if (runnerMaps.length === 0) {
        return 0;
    }
    const getBetsCountHandler = ticketHandlerByBetType[betType];
    if (getBetsCountHandler) {
        const runnerMapsNormalized: number[][] = [];
        for (let i = 0; i < runnerMaps.length; i++) {
            runnerMapsNormalized.push(runnerMaps[i] ?? []);
        }

        return getBetsCountHandler(runnerMapsNormalized, betType);
    }

    return 0;
};

export const getBetCountByRunnerMaps = (runnerMaps: number[][], isUniq = false) => {
    const [firstHorses, secondHorses] = runnerMaps;
    if (!firstHorses?.length || !secondHorses?.length) {
        return 0;
    }
    const possibleCombinations = [] as number[][];
    firstHorses.forEach(first => {
        secondHorses.forEach(second => {
            if (first === second) {
                return;
            } else {
                possibleCombinations.push([first, second].sort((a, b) => a - b));
            }
        });
    });
    return isUniq ? uniqWith(possibleCombinations, isEqual).length : possibleCombinations.length;
};

export const getBetCountByTripleRunnerMap = (runnerMaps: number[][]) => {
    const [firstHorses, secondHorses, thirdHorses] = runnerMaps;
    if (!firstHorses?.length || !secondHorses?.length || !thirdHorses?.length) {
        return 0;
    }

    const possibleCombinations = [] as number[][];
    firstHorses.forEach(first => {
        secondHorses.forEach(second => {
            thirdHorses.forEach(third => {
                const arrayToPush = [first, second, third];
                if (hasDuplicates(arrayToPush)) return;
                possibleCombinations.push(arrayToPush);
            });
        });
    });
    return possibleCombinations.length;
};

export const getBetCountByQuadrupleRunnerMap = (runnerMaps: number[][]) => {
    const [firstHorses, secondHorses, thirdHorses, fourthHorses] = runnerMaps;

    if (!firstHorses?.length || !secondHorses?.length || !thirdHorses?.length || !fourthHorses?.length) {
        return 0;
    }

    const possibleCombinations = [] as number[][];
    firstHorses.forEach(first => {
        secondHorses.forEach(second => {
            thirdHorses.forEach(third => {
                fourthHorses.forEach(fourth => {
                    const arrayToPush = [first, second, third, fourth];
                    if (hasDuplicates(arrayToPush)) return;
                    possibleCombinations.push(arrayToPush);
                });
            });
        });
    });

    return possibleCombinations.length;
};

export const getHorseRacingTopicEventName = (channel: string) => `public-lvdc-${channel}-updating`;

export const getHorseRacingSocketParams = (channel: string) => ({
    params: { service: 'lvdc', channel: channel },
    resubscribeOnReconnect: true,
});

export function notifyHorseRacingChange(
    type: 'betting-off' | 'race-ending',
    data: { nevadaTrackId: number; raceId: number },
) {
    const { nevadaTrackId, raceId } = data;
    if (nevadaTrackId && raceId) {
        const title = type === 'betting-off' ? `Betting turned off` : 'Race has ended';
        const raceTracks = getStoreValue(store.retail.raceTracks);
        const trackName = raceTracks.find(track => track.nevada_track_id === Number(nevadaTrackId))?.track_name;
        notification.info({
            message: title,
            description: `Track ${trackName || nevadaTrackId}, race #${raceId}`,
        });
        const raceBetSlip = getStoreValue(store.retail.horseRacingBetSlip);
        if (raceBetSlip.nevadaTrackId === Number(nevadaTrackId) && raceBetSlip.raceId === Number(raceId)) {
            store.retail.horseRacingBetSlip.set(initialHorseRacingBetSlipState);
        }
    }
}

import { NativeButtonProps } from 'antd/lib/button/button';
import { Notification } from '@staycool/retail-types/notification';
import { MarketInfo, ODDS_STATUS } from 'microservices/sbgate';
import { TransactionType } from '@staycool/retail-types/transaction';
import { TerminalStatus } from '@staycool/retail-types/terminal';
import { TeaserPayouts } from 'microservices/sports/payout';
import { FOParlayCardMarket, GetParlayCardFOResponse } from '@staycool/sports-types/parlay-card';
import { ObjectValues } from './util';
import { ItemToPurchaseType } from '@staycool/retail-types/otc';
import { BetType as RacebookBetType } from '@staycool/lvdc-types/bets';
import { RaceBetType } from 'microservices/lvdc-web';
import { ManualTicketVoidAcceptanceStatus } from '@staycool/bets-types';

export enum ViewMode {
    BuyTickets = 'buy tickets',
    Category = 'Category',
    InputStake = 'input stake',
    LastViewed = 'last viewed',
    Match = 'match',
    Search = 'search',
    IssueVoucher = 'issue voucher',
    Redeem = 'redeem',
}

export enum CustomerFaceRoute {
    RacingBetSlip = 'racing_bet_slip',
    BetSlip = 'bet_slip',
    Banner = 'banner',
    Total = 'total',
    IssueVoucher = 'issue_voucher',
    Redeem = 'redeem',
    Contest = 'contest',
}

export enum BetType {
    Single = 'single',
    Combo = 'combo',
    System = 'system',
    Teaser = 'teaser',
    ParlayCard = 'parlayCard',
    BetBuilder = 'betbuilder',
}

export enum PaperSelectionType {
    Selection = 'Selection',
    SelectionNumber = 'Selection Number',
    BetAmount = 'Bet Amount',
}

export interface BetSlipUserState {
    betType: BetType;
    isBetTypeForced: boolean;
    stakeByMarketId: Record<string, string>;
    bankers: string[];
}

export type ParlayCardMarketOutcome = FOParlayCardMarket['outcomes'][0];

export type ParlayCardBetslipOutcome = FOParlayCardMarket['outcomes'][0] & { marketId: number; matchId: number };

export type ParlayCardWithMarket = GetParlayCardFOResponse;

export interface PaperParlayCard {
    templateId: number;
    selections: { type: PaperSelectionType; value: number }[];
}

export type ParlayCardBetslipMarketIdToOutcomeIds = Record<number, number[]>;

export enum OtcSidebarViewMode {
    SportBetSlip = 'sport bet slip',
    RacingBetSlip = 'racing bet slip',
    Inquiry = 'inquiry',
    Navigation = 'navigation',
    Contest = 'Contest',
}

export interface Ticket {
    stake: string;
    marketIdToOutcomeId: { [marketId: number]: number };
    betType: BetType;
    systemStakes?: Record<string, string>;
    systemBets?: SystemBets;
}

export interface Shift {
    id: number;
    user_id: string;
    session_id: string;
    started_at: Date;
    ended_at?: Date;
}

export interface Summary {
    type: TransactionType;
    count: number;
    amount: number;
}

export interface CustomerFaceBetSlip {
    betSlipMarketIdToOutcomeId: { [marketId: number]: number };
    marketInfoById: { [marketId: string]: MarketInfo };
    betSlipMarketIds: string[];
    parlayCard: {
        parlayCardWithMarket: ParlayCardWithMarket;
        marketInfo: Record<number, FOParlayCardMarket>;
        betSlipMarketIdToOutcomeIds: ParlayCardBetslipMarketIdToOutcomeIds;
    };
    betType: BetType;
}

export interface RacingBetSlip {
    nevadaTrackId: number;
    nevadaTrackName: string;
    raceId: number;
    betType: RaceBetType;
    runnerMaps: number[][];
    races: number[];
    runnerMapsIndex: number;
    breed: string;
    isMultiRace: boolean;
}

export interface CustomerFaceSystemBet {
    bankers: string[];
    systemStakes: Record<string, string>;
    parentSystemStakes: { withSingles: string; noSingles: string };
    activeSystemBetType: string;
}

export interface CustomerFaceTeasers {
    teaserPayouts: TeaserPayouts;
    teaserSelectedPoint: number;
}

export interface CustomerFaceRacingBetSlipStake {
    stake: number;
    totalStake: number;
}

export interface CustomerFaceBetSlipStake {
    marketIdForStakeInput?: string;
    totalStake: number;
    potentialWin: number;
}

export interface CustomerFaceVoucherBasket {
    voucherAmount: number;
    cashAmount: number;
    changeBack: number;
}

export interface CustomerFaceTicketCash {
    cashAmount: number;
    changeBack: number;
    chipsAmount: number;
}

export const betslipErrorId = 'betSlipError';

export enum BetSlipError {
    ComboNotAllowed = 'Parlay not allowed',
    SameMatchInBetSlip = 'Same match in bet slip',
    MaxSingleSelections = 'Maximum selections for single is 15',
    MaxComboSelections = 'Maximum selections for parlay is 15',
    MaxSystemSelections = 'Maximum 10 selections for Round - Robin bet',
    MaxTeasersSelections = 'Maximum 15 selections for teasers',
    MinTeasersSelections = 'Minimum 2 selections for teasers',
    TeaserCategoryCombinationIsNotAllowed = 'Teaser category combination is not allowed',
    IncorrectAmountOfSelections = 'Incorrect amount of selections',
    WrongMarketTypeTeasers = 'This market type is not allowed for teaser bet',
    InplayNotAllowed = 'Livebet markets are not allowed',
    MissingMatch = 'Missing match',
    TeasersNotMainLine = 'Bet selections must all be on the main line markets',
    TeaserInvalidMainLine = 'Main line market odds are too wide',
    OddsChanged = 'Odds changed',
}

export interface LastViewedMatch {
    betting_end: Date;
    id: number;
    match_start: Date;
    name: string;
    slug: string;
    sport_category_id: number;
}

export interface Otc {
    id: number;
    hash: string;
    status: TerminalStatus;
    alias: string;
    address: string;
    bet_shop_id: number;
    notifications: Notification[];
}

export interface OtcInputButton extends NativeButtonProps {
    name?: string;
}

export interface OtcInputQuickButtons {
    buttons: string[] | number[];
    onAction: (item: string | number) => void;
}

export interface ActionButton extends NativeButtonProps {
    text: string;
}

export type SystemBets = Record<number, number[][]>;

export interface CustomerFaceBasketItem {
    total: number;
    amount: number;
    type: ItemToPurchaseType;
    ticketBetType?: BetType | RacebookBetType;
}

export interface CustmerFaceItemToPurchase {
    totalCount: number;
    label: string;
    amount: number;
}

export type RetailKeyboardLayout = 'default' | 'shift' | 'numpad';

export enum RetailOtcNavigation {
    Shift = 'SHIFT',
    Kiosks = 'KIOSKS',
    IssueVoucher = 'ISSUE VOUCHER',
    WebTransfer = 'WEB TRANSFERS',
    Redeem = 'REDEEM',
    PlaceBet = 'PLACE BET',
    Loyalty = 'LOYALTY',
    Inquiry = 'INQUIRY',
    Cart = 'CART',
    Contest = 'CONTEST',
}

export interface InquiryFilter {
    id?: string;
    timeFrom?: string;
    timeTo?: string;
    type: InquiryType;
    stakeFrom?: number;
    stakeTo?: number;
    amountFrom?: number;
    amountTo?: number;
    winFrom?: number;
    winTo?: number;
    inquiryFilterActiveField?: InquiryInputKey;
}

export enum InquiryType {
    Ticket = 'ticket',
    Voucher = 'voucher',
    Contest = 'contest',
}

export interface BetSlipMinimalMarket {
    id: number;
    name?: string;
    in_play: boolean;
    status: keyof typeof ODDS_STATUS;
    betting_end?: Date;
    view_type: string;
    raw_line: number;
    line?: string | number;
    marketName?: string;
    marketTypeName?: string;
    provider: number;
    sport_category_id?: number;
    outcomes: {
        id: number;
        name: string;
        result_key: string;
        status: keyof typeof ODDS_STATUS;
        market_id?: number;
        has_trading_position?: boolean;
    }[];
    team_names?: string[];
    player_names?: string[];
}
export interface BetSlipReplayState {
    betSlipMarketIdToOutcomeId: { [marketId: number]: number };
    betType: BetType;
    teaserSelectedPoint?: number;
}

export enum ShiftStatisticTab {
    Transactions = 'Transactions',
    Summary = 'Summary',
    OtherOngoinShifts = 'Other Ongoing Shifts',
}

export const MASTATUS = {
    Initial: 'Initial',
    Approved: 'Approved',
    Requested: 'Requested',
    Declined: 'Declined',
} as const;

export type MAStatus = ObjectValues<typeof MASTATUS>;

export const MAColors = {
    Requested: '#e69b00',
    Approved: '#00ff00',
    Declined: '#ff0000',
};

export enum CustomerFaceLocalStorageKey {
    Route = 'CUSTOMER_FACE_ROUTE',
    BetSlip = 'CUSTOMER_FACE_BET_SLIP',
    RacingBetSlip = 'RACING_BET_SLIP',
    IssueVoucherAmount = 'CUSTOMER_FACE_ISSUE_VOUCHER_AMOUNT',
    ItemsToPurchase = 'CUSTOMER_FACE_ITEMS_TO_PURCHASE',
    RacingBetSlipStake = 'CUSTOMER_FACE_RACING_BET_SLIP_STAKE',
    BetSlipStakeAndPotWin = 'CUSTOMER_FACE_BET_SLIP_STAKE_AND_POTENTIAL_WIN',
    Teasers = 'CUSTOMER_FACE_TEASERS',
    RedeemItem = 'CUSTOMER_FACE_REDEEM_ITEM',
    SystemBet = 'CUSTOMER_FACE_SYSTEM_BET',
    OddsByOutcomeId = 'CUSTOMER_FACE_ODDS_BY_OUTCOME_ID',
    TotalPayment = 'CUSTOMER_FACE_TOTAL_PAYMENT',
    Contest = 'CUSTOMER_FACE_ENTRIES_PURCHASE',
    CategoryIconNames = 'CUSTOMER_FACE_CATEGORY_ICON_NAMES',
}

export const LastViewedMatches = 'LAST_VIEWED_MATCHES';

export interface MAVoidTicketIds {
    sbTicketId: string;
    retailTicketId: string;
}

const ContestViewMode = {
    List: 'List',
    Show: 'Show',
} as const;

export type ContestMode = ObjectValues<typeof ContestViewMode>;

export interface ConTestPurchase {
    id: string;
    contestName: string;
    legId: number;
    entryNumber: number;
    amount: number;
    isFree?: boolean;
    entryId?: string;
    contestId: number;
    predictions: {
        [market_id: number]: {
            outcomeId: number;
            position: number;
            teamName: string;
        };
    };
}

export interface RetainedLoyalty {
    [shift_id: number]: string;
}

export const RetailRetainedLoyaltyLocalStorageKey = 'RETAIL_CART_LOYALTY_ID';

export const RetailBetPlaceProductLocalStorageKey = 'RETAIL_BET_PLACE_PRODUCT';

export enum RetailBetPlaceProduct {
    Sport = 'SPORT',
    Race = 'RACE',
}

export const RetailLastVisitedRace = 'RETAIL_LAST_VISITED_RACE';

export interface LastVisitedRace {
    nevadaTrackId?: number;
    raceId?: number;
}

export enum InquiryInputKey {
    Id = 'id',
    StakeFrom = 'stakeFrom',
    StakeTo = 'stakeTo',
    AmountFrom = 'amountFrom',
    AmountTo = 'amountTo',
}

export interface TicketMAVoidRequest {
    betsTicketId: string;
    status: ManualTicketVoidAcceptanceStatus;
}

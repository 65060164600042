import some from 'lodash/some';
import round from 'lodash/round';
import { getStoreValue, store } from 'stores/store';
import {
    BetSlipError,
    BetSlipMinimalMarket,
    BetSlipUserState,
    BetType,
    CustomerFaceBetSlip,
    CustomerFaceBetSlipStake,
    CustomerFaceRacingBetSlipStake,
    CustomerFaceSystemBet,
    CustomerFaceTeasers,
    CustomerFaceTicketCash,
    CustomerFaceVoucherBasket,
    PaperParlayCard,
    ParlayCardBetslipMarketIdToOutcomeIds,
    ParlayCardMarketOutcome,
    ParlayCardWithMarket,
    RetainedLoyalty,
    SystemBets,
    Ticket,
} from 'types/retail-otc';

import { OddsFormat } from 'microservices/bets/types';
import { DatabaseOdds } from '@staycool/odds-types';
import { Filter } from 'microservices/retail-proxy';
import { color } from 'style/variables';
import { convertAmericanToDecimal, convertDecimalToAmerican } from './odds/odds';
import map from 'lodash/map';
import { TerminalErrors, TerminalStatus } from '@staycool/retail-types/terminal';
import { Combinations } from '../cmb';
import { addZero, CategoryMatchMarketOutcome } from './market';
import {
    FoCategoryMarketType,
    FoCategoryMatch,
    FoCategoryMatchMarket,
    FoSidebetsMarketGroupMarket,
} from '@staycool/sbgate-types';
import { RetailMatchMarket } from '@staycool/sports-types/search';
import uniq from 'lodash/uniq';
import { MarketInfo, ODDS_STATUS } from 'microservices/sbgate';
import { FormattedParlayMarketBO, ParlayMarketOutcomeBO } from './sports/parlay-card/pdf-generator';
import sortBy from 'lodash/sortBy';
import { FOParlayCardMarket, ParlayCardFO } from '@staycool/sports-types/parlay-card';
import { TeaserPayouts } from 'microservices/sports/payout';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import { ItemToPurchase, ItemToPurchaseType } from '@staycool/retail-types/otc';
import { UnpaidTicket } from '@staycool/retail-types/ticket';
import { logger } from './logger';
import { notification } from 'antd';
import {
    clearMiddlewareLoyalty,
    createRetailLoyalty,
    createRetailLoyaltyById,
    getUnpaidItems,
} from '../microservices/retail-middleware';
import { TransactionStatus } from '@staycool/retail-types/transaction';
import { LoyaltyResponse } from '@staycool/retail-types/loyalty';
import { applyMask } from './util';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import dayjs from 'dayjs';
import { RetailRetainedLoyaltyLocalStorageKey } from 'types/retail-otc';
import isObject from 'lodash/isObject';
import { payoutRound } from './ticket/ticket';

export const initialBetSlipUserState = Object.freeze({
    betType: BetType.Single,
    isBetTypeForced: false,
    stakeByMarketId: Object.freeze({}),
    bankers: [],
}) as BetSlipUserState;

export const reportDateOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};

export const comboCountToSystemType = {
    3: { withSingles: 'ALL' },
    4: { withSingles: 'ALL' },
    5: { withSingles: 'ALL' },
    6: { withSingles: 'ALL' },
    7: { withSingles: 'ALL' },
    8: { withSingles: 'ALL' },
    9: { withSingles: 'ALL' },
};

export const systemBetTypeBySystemKey = {
    1: 'SINGLE',
    2: 'DOUBLE',
    3: 'TREBLE',
    4: 'FOURFOLD',
    5: 'FIVEFOLD',
    6: 'SIXFOLD',
    7: 'SEVENFOLD',
    8: 'EIGHTFOLD',
    9: 'NINEFOLD',
    10: 'TENFOLD',
};

export const otcSystemBetTypeTranslationByOriginalBetType = {
    SINGLE: 'STRAIGHT',
    DOUBLE: "2's",
    TREBLE: "3's",
    FOURFOLD: "4's",
    FIVEFOLD: "5's",
    SIXFOLD: "6's",
    SEVENFOLD: "7's",
    EIGHTFOLD: "8's",
    NINEFOLD: "9's",
    TENFOLD: "10's",
    ALL: 'ALL',
};

export const ticketTypeUsaNames = {
    single: 'single',
    combo: 'parlay',
    system: 'round-robin',
    teaser: 'teaser',
    parlayCard: 'parlay card',
};

export const ticketProduct = {
    LIVE: 'live',
    PREMATCH: 'prematch',
    MIXED: 'mixed',
};

export enum RetailIdMasks {
    VoucherIdMask = '____-____-__',
    TicketIdMask = '________-____-____-____-____________',
    VoucherIdMaskWithCurtain = '____-****-**',
    TicketIdMaskWithCurtain = '________-____-____-____-************',
    VoucherIdMaskRestricted = '____-...',
    TicketIdMaskRestricted = '________-____-...',
}

export function getTicketTotalOdds(
    marketIdToOutcomeId: { [marketId: number]: number },
    oddsByOutcomeId: Record<number, DatabaseOdds>,
    oddsFormat: OddsFormat = 'AMERICAN',
) {
    const ticketOutcomes = Object.values(marketIdToOutcomeId);

    if (!ticketOutcomes.length) {
        return 0;
    }

    if (ticketOutcomes.length === 1) {
        if (oddsFormat === 'AMERICAN') {
            return convertDecimalToAmerican(String(oddsByOutcomeId?.[ticketOutcomes[0]]?.value));
        } else {
            return oddsByOutcomeId?.[ticketOutcomes[0]]?.value;
        }
    } else {
        const allOdds = ticketOutcomes
            .map(outcomeId => oddsByOutcomeId[outcomeId] && oddsByOutcomeId[outcomeId].value)
            .filter(oddsValue => oddsValue);
        const totalOdds = allOdds.reduce((total, oddsValue) => Number(total) * Number(oddsValue), 1);

        if (oddsFormat === 'AMERICAN') {
            return convertDecimalToAmerican(String(totalOdds));
        } else {
            return totalOdds;
        }
    }
}

export function getPurchaseItemType(
    itemToPurchaseType: ItemToPurchaseType,
    label: string,
    ticketBetType?: ItemToPurchase['ticketBetType'],
) {
    if (itemToPurchaseType === ItemToPurchaseType.Ticket && ticketBetType) {
        const betType = getTicketBetType(ticketBetType);
        return `Ticket (${betType})`;
    }
    if (label === 'betbuilder') {
        return 'SGP';
    }
    return label;
}

export const otcTransactionType = {
    WEB_ACCOUNT_DEPOSIT: 'Account deposit',
    WEB_ACCOUNT_WITHDRAW: 'Account withdraw',
    TICKET_PLACED: 'Ticket issued',
    WITHDRAWAL: 'Drop',
    DEPOSIT: 'Fill',
};

export function getTicketBetType(ticketBetType: ItemToPurchase['ticketBetType']) {
    switch (ticketBetType) {
        case BetType.Single:
            return 'Straight';
        case BetType.Combo:
            return 'Parlay';
        case BetType.System:
            return 'Round - Robin';
        case BetType.Teaser:
            return 'Teaser';
        case BetType.BetBuilder:
            return 'SGP';
        default:
            return ticketBetType;
    }
}

export function calculateTotalOdds(marketId?: string) {
    const allOddsValues = getAllOdds(marketId);
    return allOddsValues.reduce(
        (totalOdds, oddsValue) =>
            (totalOdds as number) * convertAmericanToDecimal(convertDecimalToAmerican(String(oddsValue))),
        1,
    );
}

function getAllOdds(marketId?: string) {
    const betSlipMarketIdToOutcomeId = getStoreValue(store.retail.betSlipMarketIdToOutcomeId);
    const oddsByOutcomeId = getStoreValue(store.retail.oddsByOutcomeId);
    const marketIdToOutcomeId = marketId
        ? { [marketId]: betSlipMarketIdToOutcomeId[marketId] }
        : betSlipMarketIdToOutcomeId;
    return Object.values(marketIdToOutcomeId)
        .map(outcomeId => oddsByOutcomeId[outcomeId] && oddsByOutcomeId[outcomeId].value)
        .filter(oddsValue => oddsValue);
}

export function calculateBetslipParlayMaxWin(
    betSlipMarketIdToOutcomeId: { [marketId: number]: number },
    oddsByOutcomeId: Record<number, DatabaseOdds>,
    stake: string,
) {
    const totalOdds = getTicketTotalOdds(betSlipMarketIdToOutcomeId, oddsByOutcomeId, 'DECIMAL');
    if (!stake) {
        return '0';
    }

    return payoutRound((totalOdds as number) * parseFloat(stake)).toFixed(2);
}

export function calculateBetslipTeaserMaxWin(stake: string) {
    const totalOdds = getTeaserTotalOdds();
    if (!stake) {
        return '0';
    }

    return payoutRound(totalOdds * parseFloat(stake)).toFixed(2);
}

function getTeaserTotalOdds() {
    const betSlipMarketIdToOutcomeId = getStoreValue(store.retail.betSlipMarketIdToOutcomeId);
    const selectionsCount = Object.keys(betSlipMarketIdToOutcomeId).length;
    const teaserPoints = getStoreValue(store.retail.teaserSelectedPoint);
    const teaserPayouts = getStoreValue(store.retail.teaserPayouts);
    const teaserPayout = teaserPayouts?.payout_odds?.find(
        payout => payout.points === teaserPoints && payout.selections_amount === selectionsCount,
    );
    return teaserPayout?.odds || 0;
}

export function addBetSlipMarketError(marketId: string, error: BetSlipError) {
    store.retail.betSlipErrorsByMarketId.set((currentErrors: { [marketId: string]: BetSlipError[] }) => ({
        ...currentErrors,
        [marketId]: uniq([...(currentErrors[marketId] || []), error]),
    }));

    return error;
}

export function removeBetSlipError(marketId: string, error: BetSlipError) {
    store.retail.betSlipErrorsByMarketId.set((currentErrors: { [marketId: string]: BetSlipError[] }) => ({
        ...currentErrors,
        [marketId]: (currentErrors[marketId] || []).filter(currentError => currentError !== error),
    }));
}

export function removeAllSpecificBetSlipErrors(errorsToRemove: BetSlipError[]) {
    store.retail.betSlipErrorsByMarketId.set((currentErrors: { [marketId: string]: BetSlipError[] }) => {
        return Object.fromEntries(
            Object.entries(currentErrors).map(([marketId, errors]) => [
                marketId,
                (errors || []).filter(error => !errorsToRemove.includes(error)),
            ]),
        );
    });
}

export function isBetSlipLoadingMarkets() {
    const betSlipMarketIdToOutcomeId = getStoreValue(store.retail.betSlipMarketIdToOutcomeId);
    const marketInfoById = getStoreValue(store.retail.marketInfoById);
    return some(betSlipMarketIdToOutcomeId, (_, marketId) => !marketInfoById[marketId]);
}

export function checkIfBetSlipHasErrors() {
    const betSlipErrorsByMarketId = getStoreValue(store.retail.betSlipErrorsByMarketId);
    const betSlipMarketIdToOutcomeId = getStoreValue(store.retail.betSlipMarketIdToOutcomeId);
    const marketsInBetSlip = Object.keys(betSlipMarketIdToOutcomeId);
    let betSlipErrors: BetSlipError[] = [];

    marketsInBetSlip.forEach(marketId => {
        if (betSlipErrorsByMarketId[marketId]?.length) {
            betSlipErrors = [...betSlipErrors, ...betSlipErrorsByMarketId[marketId]];
        }
    });

    return betSlipErrors.length > 0;
}

export function getSystemCombinations(betSlipMarketIdToOutcomeId: { [marketId: number]: number }, bankers: string[]) {
    const systemBets = {};
    const betSlipMarketIdsWithOutBankers = Object.keys(betSlipMarketIdToOutcomeId)
        .filter(marketId => !bankers.includes(marketId))
        .slice(0, 10);

    for (let combinationLength = 1; combinationLength <= betSlipMarketIdsWithOutBankers.length; combinationLength++) {
        const combinations = Combinations(betSlipMarketIdsWithOutBankers, combinationLength);
        combinations.each(combination => {
            if (!systemBets[combinationLength]) {
                systemBets[combinationLength] = [];
            }
            systemBets[combinationLength].push(combination);
        });
    }

    return systemBets as SystemBets;
}

export function getStatusColor(status: TerminalStatus, errors?: TerminalErrors) {
    const colorByStatus = {
        [TerminalStatus.Blocked]: color.gold700,
        [TerminalStatus.Enabled]: color.green500,
        [TerminalStatus.Disabled]: color.red500,
    };
    if (status === TerminalStatus.Enabled && errors?.length) {
        return color.gold700;
    }
    return colorByStatus[status];
}

export function getTerminalStatus(status: TerminalStatus) {
    if (status === TerminalStatus.Blocked) {
        return TerminalStatus.Enabled;
    }

    return status;
}

export function formatToUsLocaleNumber(value: string | number): string {
    return Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export function formatAmount(amount: number) {
    const formatedAmount = formatToUsLocaleNumber(Math.abs(amount));
    return amount < 0 ? `- $${formatedAmount}` : `$${formatedAmount}`;
}

export function formatDate(
    date: Date,
    options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    },
) {
    return new Date(date).toLocaleString('en-US', options);
}

export function onTablePageChange(
    pageNumber: number,
    pageSize: number = 15,
    setCurrentPage: (pageNumber: number) => void,
    setPageSize: (pageNumber: number) => void,
) {
    setCurrentPage(pageNumber);
    setPageSize(pageSize);
}

export function onTableChange(
    sorter: Record<string, any>,
    orderBy: Filter['orderBy'],
    setOrderBy: (orderBy: Filter['orderBy']) => void,
) {
    const { field = orderBy?.field, order = orderBy?.direction } = sorter;
    const direction = order === 'ascend' ? 'asc' : 'desc';
    if (!(orderBy && orderBy.field === field && orderBy.direction === direction)) {
        setOrderBy({ field, direction });
    }
}

export function calculateSystemTotalStake(systemBets: SystemBets, systemStakes: Record<string, string>) {
    const totalStake = map(systemBets, (betsOfType, systemKey) => {
        const systemType = systemBetTypeBySystemKey[systemKey];
        const betStake = Number(systemStakes[systemType]);

        if (!betStake) {
            return;
        }

        return betStake * betsOfType.length;
    })
        .filter(totalBetsOfOneTypeStakeOrUndefined => totalBetsOfOneTypeStakeOrUndefined)
        .reduce((totalSystemStake, oneTypeOfSystemStake) => Number(totalSystemStake) + Number(oneTypeOfSystemStake), 0);

    return round(Number(totalStake), 2);
}

export function calculateSystemTotalPotentialReturn(
    ticket: Partial<Ticket>,
    oddsByOutcomeId: Record<number, DatabaseOdds>,
) {
    const { systemBets, systemStakes, marketIdToOutcomeId } = ticket;

    if (!systemBets || !systemStakes || !marketIdToOutcomeId) {
        return 0;
    }

    const potentialReturn = map(systemBets, (betsOfType, systemKey) => {
        const stake = systemStakes[systemBetTypeBySystemKey[systemKey]];

        if (!stake) {
            return;
        }

        return betsOfType
            .map(marketIdsForBet => {
                const betTotalOdds = marketIdsForBet
                    .map(marketId => {
                        const outcomeId = marketIdToOutcomeId[marketId];
                        return oddsByOutcomeId[outcomeId] ? oddsByOutcomeId[outcomeId].value : 1;
                    })
                    .reduce(
                        (oddsMultipliedValue: number, currentOddsValue) =>
                            oddsMultipliedValue * (currentOddsValue || 1),
                        1,
                    );

                return payoutRound(Number(stake) * betTotalOdds);
            })
            .reduce((totalBetsPotentialRetun, betPotentialReturn) => totalBetsPotentialRetun + betPotentialReturn, 0);
    })
        .filter(systemBetPotentialReturnOrUndefined => systemBetPotentialReturnOrUndefined)
        .reduce(
            (totalSystemBetSystemTypePotentialReturn: number, systemBetSystemTypePotentialReturn) =>
                totalSystemBetSystemTypePotentialReturn + (systemBetSystemTypePotentialReturn || 0),
            0,
        );

    return payoutRound(potentialReturn);
}

export function formatLine(rawLine: number) {
    return rawLine % 1 !== 0 ? rawLine : `${rawLine}.0`;
}

export const initialCustomerFaceState: CustomerFaceBetSlip = {
    betSlipMarketIdToOutcomeId: {},
    marketInfoById: {},
    betSlipMarketIds: [],
    betType: BetType.Single,
    parlayCard: {} as CustomerFaceBetSlip['parlayCard'],
};

export const initialCustomerFaceSystemBet: CustomerFaceSystemBet = {
    bankers: [],
    systemStakes: {} as Record<string, string>,
    parentSystemStakes: {} as { withSingles: string; noSingles: string },
    activeSystemBetType: '',
};

export const initialCustomerFaceTeasers: CustomerFaceTeasers = {
    teaserPayouts: {} as TeaserPayouts,
    teaserSelectedPoint: 0,
};

export const initialCustomerFaceVoucherBasket: CustomerFaceVoucherBasket = {
    cashAmount: 0,
    voucherAmount: 0,
    changeBack: 0,
};

export const initialCustomerFaceTicketCash: CustomerFaceTicketCash = {
    cashAmount: 0,
    changeBack: 0,
    chipsAmount: 0,
};

export function getIsShortMarketDisplayMode(match: FoCategoryMatch, marketTypes: FoCategoryMarketType[]) {
    const uniqMarkets = uniqBy(match.markets, 'market_type_id');
    const marketsByMarketTypeId = groupBy(uniqMarkets, 'market_type_id');
    const outcomes = marketTypes.reduce((result, marketType) => {
        if (!marketsByMarketTypeId[marketType.id]) {
            return (result += 2);
        } else {
            return (result += marketsByMarketTypeId[marketType.id][0].outcomes.length || 2);
        }
    }, 0);
    return outcomes > 6;
}
export const initialCustomerFaceStakeAndPotentialWin: CustomerFaceBetSlipStake = {
    totalStake: 0,
    potentialWin: 0,
};

export const initialCustomerFaceRacingBetSlipStake: CustomerFaceRacingBetSlipStake = {
    totalStake: 0,
    stake: 0,
};

export function getLine(
    viewType,
    outcome: CategoryMatchMarketOutcome | ParlayMarketOutcomeBO,
    market: FoCategoryMatchMarket | FoSidebetsMarketGroupMarket | RetailMatchMarket | BetSlipMinimalMarket,
) {
    const firstTeamName = market?.team_names?.length === 2 ? market.team_names[0] : undefined;
    const lastTeamName = market?.team_names?.length === 2 ? market.team_names[1] : undefined;
    if (
        !['line', 'teaser'].includes(viewType) ||
        !outcome ||
        ['Over', 'Under'].includes(outcome.result_key) ||
        !market
    ) {
        return;
    }

    const firstLine =
        Number(market.raw_line) > 0 ? `+${addZero(Number(market.raw_line))}` : addZero(Number(market.raw_line));
    const secondLine =
        Number(market.raw_line) * -1 > 0
            ? `+${addZero(Number(market.raw_line) * -1)}`
            : addZero(Number(market.raw_line) * -1);

    if (['[Home]', '[Player 1]', firstTeamName].includes(outcome.result_key)) {
        return firstLine;
    }
    if (['[Away]', '[Player 2]', lastTeamName].includes(outcome.result_key)) {
        return secondLine;
    }

    if (market.outcomes.length === 2) {
        if (market.outcomes[0].id === outcome.id) {
            return firstLine;
        }
        if (market.outcomes[1].id === outcome.id) {
            return secondLine;
        }
    }
}

export function getOverUnderLine(
    viewType: string,
    outcome: FoCategoryMatchMarket['outcomes'][0] | FoSidebetsMarketGroupMarket['outcomes'][0] | ParlayMarketOutcomeBO,
    market: FoCategoryMatchMarket | FoSidebetsMarketGroupMarket | RetailMatchMarket | FormattedParlayMarketBO,
    shouldDisplayLetter: boolean = true,
    forcedLine: string = '',
) {
    if (viewType === 'line' && outcome && ['Over', 'Under'].includes(outcome.result_key) && market) {
        const line = `${shouldDisplayLetter ? outcome.name.slice(0, 1) : ''} ${addZero(Number(market.raw_line))}`;
        return forcedLine || line;
    }
    return null;
}

export function getMarketName(
    marketInfo: MarketInfo,
    outcome: {
        id: number;
        status: keyof typeof ODDS_STATUS;
        name: string;
        result_key: string;
    },
) {
    const { marketTypeName, marketNameWithoutLine, marketName, raw_line, player_names, team_names } = marketInfo;

    const templateRegex = /\[(.*)]/;

    if (player_names || team_names) {
        return marketName;
    }

    if (raw_line && (marketTypeName || marketNameWithoutLine) && outcome) {
        if (templateRegex.test(marketTypeName as string) || templateRegex.test(marketNameWithoutLine as string)) {
            return marketName;
        }
        return `${marketTypeName || marketNameWithoutLine} ${getOutcomeDisplayLine(outcome.result_key, raw_line)}`;
    }
    return marketName;
}

const homeRegex = /\[(Home|Player 1)]/;
const awayRegex = /\[(Away|Player 2)]/;

function getOutcomeDisplayLine(outcomeResultKey: string, displayLine: number) {
    if (Number.isNaN(Number(displayLine))) {
        return '';
    }

    if (
        outcomeResultKey.toLowerCase().includes('over') ||
        outcomeResultKey.toLowerCase().includes('under') ||
        displayLine === 0
    ) {
        return `${displayLine}`;
    }
    if (homeRegex.test(outcomeResultKey) || outcomeResultKey.includes('Draw')) {
        return `${displayLine > 0 ? '+' : '-'}${Math.abs(displayLine)}`;
    }
    if (awayRegex.test(outcomeResultKey)) {
        return `${displayLine > 0 ? '-' : '+'}${Math.abs(displayLine)}`;
    }
    return '';
}

export function getTeasersLine(
    market: BetSlipMinimalMarket,
    outcome: { id: number; name: string; result_key: string },
    addTotalsPrefix = true,
    teaserPoints?: number,
) {
    if (market.view_type !== 'line' || !outcome) {
        return;
    }

    if (['Over', 'Under'].includes(outcome.result_key)) {
        let line = market.raw_line;
        if (teaserPoints) {
            const points = outcome.result_key === 'Over' ? teaserPoints * -1 : teaserPoints;
            line = line + points;
        }
        return addTotalsPrefix ? `${outcome.name.slice(0, 1)} ${formatLine(line)}` : formatLine(line);
    }

    const firstTeamName = market.team_names?.length === 2 ? market.team_names[0] : undefined;
    const lastTeamName = market.team_names?.length === 2 ? market.team_names[1] : undefined;

    const firstRawLine = teaserPoints ? market.raw_line + teaserPoints : market.raw_line;
    const secondRawLine = teaserPoints ? market.raw_line * -1 + teaserPoints : market.raw_line * -1;
    const firstLine = firstRawLine > 0 ? `+${formatLine(firstRawLine)}` : formatLine(firstRawLine);
    const secondLine = secondRawLine > 0 ? `+${formatLine(secondRawLine)}` : formatLine(secondRawLine);

    if (['[Home]', '[Player 1]', firstTeamName].includes(outcome.result_key)) {
        return firstLine;
    }
    if (['[Away]', '[Player 2]', lastTeamName].includes(outcome.result_key)) {
        return secondLine;
    }

    if (market.outcomes.length === 2) {
        if (market.outcomes[0].id === outcome.id) {
            return firstLine;
        }
        if (market.outcomes[1].id === outcome.id) {
            return secondLine;
        }
    }
}

export function calculateParlayCardTotalOdds() {
    const betSlipMarketIdToOutcomeIds = getStoreValue(store.retail.parlayCard.betSlipMarketIdToOutcomeIds);
    const parlayCard = getStoreValue(store.retail.parlayCard.parlayCard);
    const selectionsCount = Object.values(betSlipMarketIdToOutcomeIds).flat().length;
    const payout = parlayCard?.payouts?.find(p => p.selections_amount === selectionsCount);
    return payout?.odds || 0;
}

export function getParlayCardMaxSelections(parlayCard: ParlayCardFO) {
    if (!parlayCard?.payouts?.length) {
        return;
    }
    return Math.max(...parlayCard.payouts.filter(p => p.odds > 1).map(p => p.selections_amount));
}

export function getMarketTypeInfo(result_keys: string[], view_type: string) {
    const resultKeys = result_keys.map(r => r.toLowerCase());
    const isTotal = resultKeys.some(r => r.includes('over') || r.includes('under')) && view_type === 'line';
    const isHandicap = resultKeys.some(r => r.includes('home') || r.includes('away')) && view_type === 'line';

    return { isTotal, isHandicap };
}

export function isParlayCardOutcomeDisabled(outcome: ParlayCardMarketOutcome, market: FOParlayCardMarket) {
    const oddsByOutcomeId = getStoreValue(store.retail.parlayCard.oddsByOutcomeId);
    const odds = market.outcomes.map(o => oddsByOutcomeId[o.id]).filter(Boolean);
    const { should_check_master_market, status: parlayCardStatus } = getStoreValue(store.retail.parlayCard.parlayCard);
    const areOddsValid =
        !should_check_master_market || (odds.length === market.outcomes.length && areOddsValidForOnDemandBet(odds));
    const isOutcomeDisabled = !outcome?.is_open;
    const isMatchDisabled = market.match_status !== 'OPEN';
    return parlayCardStatus !== 'OPEN' || isOutcomeDisabled || isMatchDisabled || !areOddsValid;
}

export function removeOutcomeFromBetslip(outcomeId: number, marketId: number) {
    store.retail.parlayCard.betSlipMarketIdToOutcomeIds.set(state => {
        const outcomeIds = state[marketId];
        if (outcomeIds.length > 1) {
            state[marketId] = outcomeIds.filter(id => id !== outcomeId);
            return;
        }
        delete state[marketId];
    });
}

export function clearParlayCard(setBetTypeToSingle = true) {
    store.retail.parlayCard.paperParlayCard.set({} as PaperParlayCard);
    store.retail.parlayCard.parlayCard.set({} as ParlayCardWithMarket);
    store.retail.parlayCard.betSlipMarketIdToOutcomeIds.set({} as ParlayCardBetslipMarketIdToOutcomeIds);
    store.retail.parlayCard.marketInfo.set({} as Record<number, FOParlayCardMarket>);
    store.retail.parlayCard.oddsByOutcomeId.set({} as Record<number, DatabaseOdds>);
    store.retail.parlayCard.twoSelectionMarkets.set([] as FOParlayCardMarket[]);
    if (setBetTypeToSingle) {
        store.retail.betSlipUserState.set(state => ({ ...state, betType: BetType.Single }));
    }
}

export function getParlayCardOutcomeLine(
    market: FOParlayCardMarket,
    outcome: ParlayCardMarketOutcome,
    teaserPoints: number,
) {
    const { id, view_type, line } = market;

    const betSlipMiniMarket = {
        id,
        in_play: false,
        status: outcome?.is_open ? 'OPEN' : 'DISABLED',
        view_type,
        raw_line: line,
    } as BetSlipMinimalMarket;

    return getTeasersLine(betSlipMiniMarket, outcome, false, teaserPoints);
}

export function areOddsValidForOnDemandBet(odds: DatabaseOdds[]) {
    if (odds.some(o => o.status !== 'OPEN')) {
        return false;
    }
    const maxOddsRatio = 1.23;
    const [smallerOdds, biggerOdds] = sortBy(odds.map(x => x.value));
    const oddsRatio = round(biggerOdds / Math.max(smallerOdds, 1), 3);
    return oddsRatio < maxOddsRatio;
}

function findSameMarketTypeSelectionInBetslip(market: FOParlayCardMarket) {
    const markets = getStoreValue(store.retail.parlayCard.marketInfo);
    const betSlipMarketIdToOutcomeIds = getStoreValue(store.retail.parlayCard.betSlipMarketIdToOutcomeIds);
    const marketIdsInBetslip = Object.keys(betSlipMarketIdToOutcomeIds).map(Number);
    return Object.values(markets)
        .filter(mr => marketIdsInBetslip.includes(mr.id))
        .find(mr => mr.match_id === market.match_id && mr.market_type_id === market.market_type_id);
}

export function selectParlayCardOutcome(outcome: ParlayCardMarketOutcome, market: FOParlayCardMarket) {
    const betSlipMarketIdToOutcomeIds = getStoreValue(store.retail.parlayCard.betSlipMarketIdToOutcomeIds);
    if (betSlipMarketIdToOutcomeIds[market.id]?.includes(outcome.id)) {
        removeOutcomeFromBetslip(outcome.id, market.id);
        return;
    }

    const { same_market_selections_allowed } = getStoreValue(store.retail.parlayCard.parlayCard);

    const sameMarketTypeSelectionInBetslip = findSameMarketTypeSelectionInBetslip(market);
    store.retail.parlayCard.betSlipMarketIdToOutcomeIds.set(state => {
        if (sameMarketTypeSelectionInBetslip && !same_market_selections_allowed) {
            delete state[sameMarketTypeSelectionInBetslip.id];
        }
        const existingOutcomeIds = state[market.id] || [];
        state[market.id] = [...existingOutcomeIds, outcome.id];
    });
}

export function getSortedMarketIds(parlayCard: {
    parlayCard: ParlayCardWithMarket;
    marketInfo: Record<number, FOParlayCardMarket>;
    betSlipMarketIdToOutcomeIds: ParlayCardBetslipMarketIdToOutcomeIds;
}) {
    const betslipOutcomeIds = Object.values(parlayCard.betSlipMarketIdToOutcomeIds).flat();
    if (isEmpty(parlayCard)) {
        return [];
    }
    const outcomes = Object.values(parlayCard.marketInfo).flatMap(market =>
        market.outcomes.map(outcome => ({ ...outcome, marketId: market.id, matchId: market.match_id })),
    );
    const sortedOutcomes = sortBy(outcomes, 'position');
    return sortedOutcomes.filter(outcome => betslipOutcomeIds.find(outcomeId => outcomeId === outcome.id));
}

export function getParlayCardOutcomeName(
    market: FOParlayCardMarket,
    outcome: ParlayCardMarketOutcome,
    teaserPoints: number,
    displayHandicapLine = true,
) {
    const outcomeLine = getParlayCardOutcomeLine(market, outcome, teaserPoints);
    const { isTotal, isHandicap } = getMarketTypeInfo(market.result_keys, market.view_type);

    const getTeamName = () => {
        return outcome?.name === '[Home]' ? market.home_team_name : market.away_team_name;
    };

    if (isTotal) {
        return `${outcome?.name} ${outcomeLine}`;
    }

    if (isHandicap && displayHandicapLine) {
        return `${getTeamName()} ${outcomeLine}`;
    }

    return getTeamName();
}

export function validateParlayCardBetslipSelections(stakeValue?: number) {
    const betSlipMarketIdToOutcomeIds = getStoreValue(store.retail.parlayCard.betSlipMarketIdToOutcomeIds);
    const betslipOutcomeIds = Object.values(betSlipMarketIdToOutcomeIds).flat();
    const parlayCard = getStoreValue(store.retail.parlayCard.parlayCard);
    const { min_selections, same_match_selections_allowed, min_stake, max_stake, same_market_selections_allowed } =
        getStoreValue(store.retail.parlayCard.parlayCard);
    const maxSelections = getParlayCardMaxSelections(parlayCard);
    const selectionsCount = betslipOutcomeIds.length;
    const marketInfo = getStoreValue(store.retail.parlayCard.marketInfo);
    const outcomes = Object.values(marketInfo).flatMap(market =>
        market.outcomes.map(outcome => ({ ...outcome, marketId: market.id, matchId: market.match_id })),
    );
    const sortedOutcomes = sortBy(outcomes, 'position');
    const outcomesOnBetslip = sortedOutcomes.filter(outcome =>
        betslipOutcomeIds.find(outcomeId => outcomeId === outcome.id),
    );
    const matchIds = outcomesOnBetslip.map(o => o.matchId);
    const sameMatchIdsOnBetslip = matchIds.filter((matchId, index) => matchIds.indexOf(matchId) !== index);
    const marketIds = outcomesOnBetslip.map(o => o.marketId);
    const sameMarketIdsOnBetslip = marketIds.filter((marketId, index) => marketIds.indexOf(marketId) !== index);
    const payout = parlayCard?.payouts?.find(p => p.selections_amount === selectionsCount);
    const totalOdds = payout?.odds || 0;
    const isMinSelectionError = betslipOutcomeIds.length < min_selections;
    const isMaxSelectionError = maxSelections && betslipOutcomeIds.length > maxSelections;

    if (isMinSelectionError) {
        return { isError: true, text: `Minimum amount of selections is ${min_selections}` };
    }

    if (isMaxSelectionError) {
        return { isError: true, text: `Maximum amount of selections is ${maxSelections}` };
    }

    if (!isMaxSelectionError && !isMinSelectionError && totalOdds <= 1) {
        return { isError: true, text: 'Incorrect amount of selections' };
    }

    if (!same_match_selections_allowed && sameMatchIdsOnBetslip.length) {
        return { isError: true, text: 'Same match in betslip is not allowed' };
    }
    if (!same_market_selections_allowed && sameMarketIdsOnBetslip.length) {
        return { isError: true, text: 'Same market in betslip is not allowed' };
    }
    if (stakeValue && min_stake && stakeValue < min_stake) {
        return { isError: true, text: `Minimum stake amount is ${min_stake}` };
    }
    if (stakeValue && max_stake && stakeValue > max_stake) {
        return { isError: true, text: `Maximum stake amount is ${max_stake}` };
    }
    return { isError: false };
}

export const keyboardLetters = ['a', 'b', 'c', 'd', 'e', 'f'];

const frontendErrors = [
    BetSlipError.SameMatchInBetSlip,
    BetSlipError.ComboNotAllowed,
    BetSlipError.MaxComboSelections,
    BetSlipError.MaxSystemSelections,
    BetSlipError.MaxTeasersSelections,
    BetSlipError.MinTeasersSelections,
    BetSlipError.WrongMarketTypeTeasers,
    BetSlipError.TeaserCategoryCombinationIsNotAllowed,
    BetSlipError.TeasersNotMainLine,
    BetSlipError.IncorrectAmountOfSelections,
    BetSlipError.InplayNotAllowed,
];

export function removeAllNonFrontendErrors(exclusionList: BetSlipError[] = []) {
    const keepOnly = [...frontendErrors, ...exclusionList];
    store.retail.betSlipErrorsByMarketId.set(errorsByMarketId => {
        Object.keys(errorsByMarketId).forEach(marketId => {
            errorsByMarketId[marketId] = (errorsByMarketId[marketId] || []).filter(error => keepOnly.includes(error));
        });
    });
}

export function formatUnpaidTicketsToItemsToPurchase(unpaidTickets: UnpaidTicket[]) {
    return unpaidTickets.map(unpaidTicket => ({
        id: unpaidTicket.id,
        type: ItemToPurchaseType.Ticket,
        ticketBetType: unpaidTicket.betType,
        amount: unpaidTicket.stake,
    }));
}

export async function setUnpaidRetailItems() {
    try {
        const unpaidItems = await getUnpaidItems();
        const unpaidIssuedTickets = unpaidItems.filter(item => item.type === ItemToPurchaseType.Ticket);
        store.retail.unpaidTickets.set(unpaidIssuedTickets);
        store.retail.itemsToPurchase.set(unpaidItems);
        return unpaidItems;
    } catch (error) {
        logger.log('RetailService', 'setUnpaidRetailItems', error);
    }
}

export function removeRetainedLoyalty(shift_id: number) {
    const retainedLoyalty: RetainedLoyalty = JSON.parse(
        localStorage.getItem(RetailRetainedLoyaltyLocalStorageKey) || '[]',
    );
    const currentShiftRetainedLoyalty = retainedLoyalty[shift_id];

    if (currentShiftRetainedLoyalty) {
        if (!retainedLoyalty || Object.keys(retainedLoyalty).length < 2) {
            localStorage.removeItem(RetailRetainedLoyaltyLocalStorageKey);
            return;
        }

        delete retainedLoyalty[shift_id];
        localStorage.setItem(RetailRetainedLoyaltyLocalStorageKey, JSON.stringify(retainedLoyalty));
    }
}

export function retainLoyalty(loyalty_id: string, shift_id: number) {
    const retainedLoyalty: RetainedLoyalty = JSON.parse(
        localStorage.getItem(RetailRetainedLoyaltyLocalStorageKey) || '{}',
    );

    const currentShiftRetainedLoyalty = retainedLoyalty[shift_id];
    if (retainedLoyalty) {
        if (!currentShiftRetainedLoyalty) {
            retainedLoyalty[shift_id] = loyalty_id;
            localStorage.setItem(RetailRetainedLoyaltyLocalStorageKey, JSON.stringify(retainedLoyalty));
        }
        return;
    }
    localStorage.setItem(RetailRetainedLoyaltyLocalStorageKey, JSON.stringify({ [shift_id]: loyalty_id }));
}

export async function handleCreateRetailLoyalty(
    loyaltyData: string | Array<string>,
    onLoyaltyError?: (error: any) => void,
) {
    const unpaidItems = getStoreValue(store.retail.itemsToPurchase);
    const currShiftId = getStoreValue(store.retail.shift)?.id;
    const currLoyaltyId = getStoreValue(store.retail.loyalty)?.loyalty_id;
    const retainedLoyalty: RetainedLoyalty = JSON.parse(
        localStorage.getItem(RetailRetainedLoyaltyLocalStorageKey) || '{}',
    );

    if (unpaidItems.length && currShiftId && currLoyaltyId && retainedLoyalty[currShiftId]) {
        notification.error({ message: 'Please complete checkout' });
        return;
    }

    try {
        const isLoyaltyId = typeof loyaltyData === 'string';
        const loyalty = isLoyaltyId
            ? await createRetailLoyaltyById(loyaltyData)
            : await createRetailLoyalty(loyaltyData);
        store.retail.loyalty.set(loyalty);
        const unpaidItems = getStoreValue(store.retail.itemsToPurchase);
        if (loyalty.loyalty_id && currShiftId && !unpaidItems.length) {
            retainLoyalty(loyalty.loyalty_id, currShiftId);
        }
    } catch (error) {
        const meta: LoyaltyResponse['meta'] = error?.errors?.[0]?.meta;
        if (meta) {
            store.retail.loyalty.set({ meta });
        } else {
            logger.log('RetailService', 'handleCreateRetailLoyalty', error);
        }
        if (onLoyaltyError) {
            onLoyaltyError(error);
        }
    }
}

export async function clearRetailLoyalty(isRetainLocal?: boolean) {
    try {
        await clearMiddlewareLoyalty();
        store.retail.loyalty.set({} as LoyaltyResponse);
        if (!isRetainLocal) {
            const currShiftId = getStoreValue(store.retail.shift)?.id;
            currShiftId && removeRetainedLoyalty(currShiftId);
        }
    } catch (error) {
        logger.log('RetailService', 'clearRetailLoyalty', error);
        notification.error({ message: 'Failed to clear middleware loyalty' });
    }
}

export function getActionLogTicketVoucherMask(value: string, type: string) {
    if (type !== 'ticket' && type !== 'voucher') {
        return value;
    }
    const splitId = value.split(': ');
    return type === 'voucher'
        ? `${splitId[0]}: ${applyMask(splitId[1], RetailIdMasks.VoucherIdMaskRestricted)}`
        : `${splitId[0]}: ${applyMask(splitId[1], RetailIdMasks.TicketIdMaskRestricted)}`;
}

export function ticketTypeFormatter(type?: string) {
    if (type?.toLowerCase() === 'betbuilder') {
        return 'SGP';
    }
    return type || '';
}

export function getFilter(filter, isReportType = false) {
    const ignoredFields = ['type', 'inquiryFilterActiveField'];
    !isReportType && ignoredFields.push('report');
    return omitBy(omit(filter, ignoredFields), field => (isObject(field) ? isEmpty(field) : !field));
}

export function getTerminalInitializationUrl(machineId: string) {
    const { TERMINAL_INITIALIZATION_URL } = getStoreValue(store.environment);
    const url = new URL(TERMINAL_INITIALIZATION_URL);
    url.searchParams.append('hash', machineId);
    return url.href;
}

export const transactionColorByStatus: Record<TransactionStatus, string> = {
    [TransactionStatus.Pending]: color.gold500,
    [TransactionStatus.Voided]: color.red400,
    [TransactionStatus.Finalized]: color.green500,
};

export function getReportTimestamp() {
    return dayjs().format('MM-DD-YYYY-hh-mm-ss-a');
}

export const TIMEZONES = [
    'America/Adak',
    'America/Anchorage',
    'America/Araguaina',
    'America/Asuncion',
    'America/Atikokan',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Cayenne',
    'America/Chicago',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver:',
    'America/Detroit',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Nuuk',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Europe/Tallinn',
    'Europe/Stockholm',
    'Europe/Helsinki',
];

export interface UserMinimalFilter {
    name?: string;
    orderBy?: string;
}

export const retailPageSizeOptions = [50, 100, 250];
export const retailDefaultPageSize = 50;

export enum ReportDatePresets {
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    THIS_WEEK = 'This week',
    LAST_WEEK = 'Last week',
    LAST_6_HOURS = 'Last 6 hours',
    LAST_12_HOURS = 'Last 12 hours',
}

import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from 'microservices/feeder';
import { parseSetting } from 'services/cms';
import { Cms } from 'types/cms';

export function useCmsSettingSync(cmsKey: string, setRecord: (cms?: Cms[]) => void) {
    function onCmsUpdate({ setting }) {
        const cms = parseSetting(setting);
        setRecord(cms);
    }

    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'cms',
            channel: cmsKey,
            keepAlive: true,
        },
    });

    useSocketTopicEvents(`publish-setting-${cmsKey}`, onCmsUpdate, []);
}

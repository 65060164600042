import dayjs, { Dayjs, ConfigType, ManipulateType, isDayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { IS_AMERICAN_ODDS_FORMAT } from 'services/odds/odds';

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.locale('en');
dayjs.updateLocale('en', {
    weekStart: localStorage.getItem(IS_AMERICAN_ODDS_FORMAT) === 'true' ? 0 : 1,
});
export { isDayjs };
export type { Dayjs, ConfigType, ManipulateType };
export default dayjs;

import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { ColumnsType } from 'antd/lib/table';
import { TicketList } from 'microservices/sportsbook-reports';
import { SettingOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, Popover, Row, Table, TableProps, Tooltip } from 'antd';
import { getComponentHeight, getHeightToFitInWindow } from 'services/browser';
import { useWindowSize } from 'hooks/useWindowSize';
import classNames from 'classnames';
import { RowClassName } from 'rc-table/lib/interface';
import { UiButton } from '../button/UiButton';

interface Props extends TableProps<any> {
    allowToggleColumns?: boolean;
    defaultHiddenColumns?: string[];
    renderHeader?: () => JSX.Element;
    bottomOffset?: number;
    customTableHeight?: number;
    customTableClass?: string;
    hasSeparatedRows?: boolean;
    isPaginationOffset?: boolean;
    handleHiddenColumns?: (hiddenColumns: string[]) => void;
}

export function UiTable(props: Props) {
    const {
        customTableHeight,
        customTableClass,
        className,
        scroll,
        isPaginationOffset = true,
        handleHiddenColumns,
        ...cleanedProps
    } = props;
    const [tableHeight, setTableHeight] = useState<number>(customTableHeight || 500);
    const [sortIndex, setSortIndex] = useState(0);
    const [isIndeterminate, setIsIndeterminate] = useState(false);
    const sortStatesTooltip = ['clear sorting', 'sort ascend', 'sort descend'];
    const formattedClassNames = className?.split(' ') || [];

    const defaultProps: Props = {
        scroll: {
            x: '100%',
            y: tableHeight < 300 ? 500 : tableHeight,
            ...scroll,
        },
        ...cleanedProps,
    };
    const {
        dataSource,
        renderHeader,
        allowToggleColumns = false,
        defaultHiddenColumns = [],
        columns,
        bottomOffset = 70,
        hasSeparatedRows = true,
        rowClassName,
        ...tableGenericProps
    } = defaultProps;
    const items = dataSource ? (dataSource as Record<string, any>[]) : [];
    const { width, height } = useWindowSize();

    const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns);

    useEffect(() => {
        const tableClass = customTableClass || '.ant-table-body';
        setTableHeight(
            getHeightToFitInWindow(tableClass, bottomOffset, height) -
                (isPaginationOffset && props.pagination ? getComponentHeight('.ant-table-pagination') : 0),
        );
    }, [width, height, props.pagination]);

    useEffect(() => {
        if (columns) {
            setIsIndeterminate(!!hiddenColumns.length && hiddenColumns.length !== columns.length);
        }
        handleHiddenColumns?.(hiddenColumns);
    }, [hiddenColumns]);

    const toggleColumnVisibility = (column: string) =>
        hiddenColumns.includes(column)
            ? setHiddenColumns(hiddenColumns.filter(c => c !== column))
            : setHiddenColumns([...hiddenColumns, column]);

    const getColumnsToDisplay = (columns: ColumnsType<TicketList>) =>
        columns.filter(c => (c.title ? !hiddenColumns.includes(c.title as string) : true));

    const tableToDisplay = (columns: ColumnsType<TicketList>) => columns.length !== hiddenColumns.length;

    function onToggleAllColumns(columns: ColumnsType<TicketList>) {
        setIsIndeterminate(false);
        if (columns.length !== hiddenColumns.length) {
            setHiddenColumns(columns.map(c => c.title as string));
        } else {
            setHiddenColumns([]);
        }
    }

    function renderToggleColumns(columns: ColumnsType<TicketList>) {
        return (
            <Wrapper>
                <Checkbox
                    indeterminate={isIndeterminate}
                    checked={hiddenColumns.length <= 0}
                    onClick={() => onToggleAllColumns(columns)}
                >
                    Select all
                </Checkbox>
                <Divider className="divider" />
                <Checkbox.Group
                    value={columns
                        .map(c => c.title as string)
                        .filter(column => column && !hiddenColumns.includes(column))}
                >
                    <Row>
                        {columns.map(column => {
                            if (!column.title) {
                                return null;
                            }
                            return (
                                <Col key={column.title as string} span={24} md={12} lg={8}>
                                    <Checkbox
                                        value={column.title}
                                        onClick={() => toggleColumnVisibility(column.title as string)}
                                    >
                                        <>{column.title}</>
                                    </Checkbox>
                                </Col>
                            );
                        })}
                    </Row>
                </Checkbox.Group>
            </Wrapper>
        );
    }

    if (!columns) {
        return null;
    }

    function handleTableSortChange() {
        setSortIndex(i => (i + 1) % 3);
    }

    function prepareRowClassName(rowClassName?: string | RowClassName<any>): RowClassName<any> {
        return (record, index, indent) => {
            let passedRowClassName;
            if (!rowClassName) {
                passedRowClassName = '';
            } else if (typeof rowClassName === 'string') {
                passedRowClassName = rowClassName;
            } else if (typeof rowClassName === 'function') {
                passedRowClassName = rowClassName(record, index, indent);
            }
            return classNames(passedRowClassName, { [index % 2 ? 'even' : 'odd']: hasSeparatedRows });
        };
    }

    return (
        <Wrapper>
            {(!!renderHeader || allowToggleColumns) && (
                <div className="header">
                    <div className="header-additional-content">{!!renderHeader && renderHeader()}</div>
                    {allowToggleColumns && (
                        <div className="toggle-columns-container">
                            <Popover
                                placement="bottomRight"
                                content={() => renderToggleColumns(columns)}
                                trigger="click"
                            >
                                <Tooltip title="Column settings">
                                    <UiButton className="toggle-columns-popover-btn" icon={<SettingOutlined />} />
                                </Tooltip>
                            </Popover>
                        </div>
                    )}
                </div>
            )}
            {tableToDisplay(columns) && (
                <Table
                    rowClassName={prepareRowClassName(rowClassName)}
                    dataSource={items}
                    columns={allowToggleColumns ? getColumnsToDisplay(columns) : columns}
                    showSorterTooltip={{
                        placement: 'bottom',
                        title: `Click to ${sortStatesTooltip[sortIndex]}`,
                    }}
                    onChange={handleTableSortChange}
                    className={classNames([...formattedClassNames, 'ui-table'], {
                        'separated-row-table': hasSeparatedRows,
                    })}
                    {...tableGenericProps}
                />
            )}
        </Wrapper>
    );
}

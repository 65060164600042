import { parse, stringify } from 'query-string';
import { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import * as reactRouterDom from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
import { matchPath } from 'react-router-dom';
import { getStoreValue, store } from 'stores/store';
import isEmpty from 'lodash/isEmpty';
import { newRoutes, oldRoutes, ROUTES } from 'routes';

export function useRouter() {
    const { useHistory, useLocation, useParams } = reactRouterDom as any;
    const history = useHistory();
    const navigateTo = history.push;
    const queryParams = parse(useLocation().search);

    function setQuery(query?: object) {
        const mergedQueries = { ...queryParams, ...query };
        for (const query in mergedQueries) {
            if (!mergedQueries[query]?.length) {
                delete mergedQueries[query];
            }
        }
        const url = `${window.location.pathname}`;
        if (!query) {
            navigateTo(url);
            return;
        }
        navigateTo(`${url}?${stringify(mergedQueries, { arrayFormat: 'comma' })}`);
    }

    return {
        location: useLocation(),
        history,
        navigateTo,
        setQuery,
        params: useParams() || {},
        queryParams,
        goBack: history.goBack,
        pathname: useLocation().pathname,
    };
}

export function getRoute(route: {} | string, params: {} = {}): string {
    return Object.keys(params).reduce(
        (route, key) => route.replace(`:${key}`, params[key]),
        (route as { '@route': string })['@route'] || (route as string),
    );
}
export function getOldRoute(route: keyof typeof oldRoutes) {
    return `${getRoute(ROUTES.oldBackoffice)}/${route}`;
}

export function getNewRoute(route: keyof typeof newRoutes) {
    return `${getRoute(ROUTES.newBackoffice)}/${route}`;
}

export function getOldBackofficeUrl(url: string, params: {} = {}): string {
    const { OLD_BACKOFFICE_URL } = getStoreValue(store.environment);
    url = !isEmpty(params) ? `${url}?${stringify(params)}` : url;
    return `${OLD_BACKOFFICE_URL}/${url}`;
}

export function getNewBackofficeUrl(url: string, params: {} = {}): string {
    const { NEW_BACKOFFICE_URL } = getStoreValue(store.environment);
    url = !isEmpty(params) ? `${url}?${stringify(params)}` : url;
    return `${NEW_BACKOFFICE_URL}/${url}`;
}

export function useRoutes(allRoutes: RouteProps[]) {
    const [routes] = useState(allRoutes.filter(Boolean));
    return routes;
}

export function isMiddlewareActiveRoute(route, exact = true) {
    return Boolean(
        matchPath(window.location.pathname, {
            path: route,
            exact,
        }),
    );
}

const getLastPathElementWithoutQuery = (path: string) => path.split('/').slice(-1)[0];
export const currentLink = location => getLastPathElementWithoutQuery(location.pathname);
export const getKeyFromRoute = (route: string) => getLastPathElementWithoutQuery(route);
export const getRootRouteName = (path: string) => path.split('/').slice(1)[0];
export const getSettingsMenuPath = (path: string) => {
    return `/${path.split('/').slice(1, 4).join('/')}`;
};

function generateRoutes(routes, routeBase, key) {
    routeBase = `${routeBase}/${kebabCase(key)}`;
    Object.keys(routes).forEach(key => {
        if (!key.startsWith('@')) {
            generateRoutes(routes[key], routeBase, key);
        }
    });

    routes['@route'] = routes['@params']
        ? Object.keys(routes['@params']).reduce(
              (route, paramKey) => route.concat(`:${routes['@params'][paramKey]}/`),
              routeBase.concat('/'),
          )
        : routeBase;
}

function initRoutes() {
    Object.keys(ROUTES).forEach(key => {
        generateRoutes(ROUTES[key], '', key);
    });
}

initRoutes();

import {
    ResponseWithSummary,
    TerminalConfig,
    addQueryParamsToUrl,
    handleProxyException,
    handleProxyExceptionSilent,
} from './retail-proxy';
import { getMiddlewareUrl, httpGet, httpPost, httpPut } from 'services/api';
import { Shift, Summary } from 'types/retail-otc';
import { Transaction } from '@staycool/retail-types/transaction';
import { Ticket, VoidedTicket, TicketProduct, TicketResponse, TicketReport } from '@staycool/retail-types/ticket';
import { ExtendedVoucher, Voucher, VoucherReport } from '@staycool/retail-types/voucher';
import { Terminal } from '@staycool/retail-types/terminal';
import { CartChangeType, ItemToPurchase } from '@staycool/retail-types/otc';
import { getStoreValue, store } from 'stores/store';
import { BetPlaceRequest } from '@staycool/bets-types/bet-placing-handlers-bets';
import { ResultRequest, TicketWithMarketDetails } from './bets/bets';
import { PlaceBetPayload } from '@staycool/lvdc-types/bets';
import { WebTransferResponse } from '@staycool/retail-types/web-transfer';
import { Credentials } from '../services/keycloak';
import { LoyaltyResponse } from '@staycool/retail-types/loyalty';
import { W2gEventDetailsResponse } from './sports/w-2-g';

export interface TerminalIntegrity {
    passed: boolean;
    report?: { file: string; expected: string; checksum: string }[];
}

export interface SportsbookBetPlaceResponse {
    sportsbookTicket: TicketWithMarketDetails;
    retailTicket: Ticket;
}

export interface RacebookBetPlaceResponse {
    racebookTicket: TicketReceipt;
    retailTicket: Ticket;
}

export function createResultRequest(resultRequest: Partial<ResultRequest>) {
    const url = getMiddlewareUrl(`shift/result-request/otc/create`);
    return handleProxyException(httpPost, 'createResultRequest', url, resultRequest);
}

export function getDiagnosticIntegrity() {
    return handleProxyException(
        httpGet<TerminalIntegrity>,
        'getDiagnosticIntegrity',
        getMiddlewareUrl(`diagnostic/integrity`),
    );
}

export function getOtcMachineId() {
    const url = getMiddlewareUrl(`init/machine-id`);
    return handleProxyException(httpGet<{ machineId: string }>, 'getOtcMachineId', url);
}

export async function resetOtc() {
    const url = getMiddlewareUrl(`init/reset`);
    await handleProxyException(httpPost, 'resetOtc', url);
}

export function getOtc() {
    const url = getMiddlewareUrl(`terminal`);
    return handleProxyExceptionSilent(httpGet<Terminal>, 'getOtc', url);
}

export async function initOtc() {
    const url = getMiddlewareUrl(`init`);
    await handleProxyException(httpPost, 'initOtc', url);
}

export async function getTerminalConfig() {
    const url = getMiddlewareUrl(`config`);
    return handleProxyException(httpGet<TerminalConfig>, 'getTerminalConfig', url);
}

export async function setTerminalConfig(config: TerminalConfig) {
    const url = getMiddlewareUrl(`config`);
    return handleProxyException(httpPost<TerminalConfig>, 'setTerminalConfig', url, config);
}

export function printRetailVoucher(voucher: Partial<Voucher>) {
    const url = getMiddlewareUrl(`voucher/otc/print`);
    return handleProxyException(httpPost<void>, 'printRetailVoucher', url, voucher);
}

export function createRetailVoucher(voucher: Partial<Voucher>) {
    const url = getMiddlewareUrl(`voucher/otc`);
    return handleProxyException(httpPost<Voucher>, 'createRetailVoucher', url, voucher);
}

export function createRetailLoyalty(tracks: string[]) {
    const url = getMiddlewareUrl(`loyalty/otc`);
    return handleProxyException(httpPost<LoyaltyResponse>, 'createRetailLoyalty', url, { tracks });
}

export function createRetailLoyaltyById(loyaltyId: string) {
    const url = getMiddlewareUrl(`loyalty/otc/manually-entered`);
    return handleProxyException(httpPost<LoyaltyResponse>, 'createRetailLoyaltyById', url, { loyaltyId });
}

export function clearMiddlewareLoyalty() {
    const url = getMiddlewareUrl(`loyalty/clear`);
    return handleProxyException(httpPost, 'clearMiddlewareLoyalty', url);
}

export function placeRetailTicket(data: BetPlaceRequest, amountUc: number) {
    const shopId = getStoreValue(store.retail.otc).bet_shop_id;
    const url = getMiddlewareUrl(`ticket/place`);
    return httpPost(url, {
        data,
        shopId,
        amount_uc: amountUc,
    });
}

export function placeRaceTicket(betPlacingData: PlaceBetPayload, amountUc: number) {
    const url = getMiddlewareUrl(`ticket/horse-racing-place`);
    return handleProxyException(httpPost<RacebookBetPlaceResponse>, 'placeRaceTicket', url, {
        betPlacingData: {
            ...betPlacingData,
            extra_info: {
                ...betPlacingData.extra_info,
            },
        },
        amount_uc: amountUc,
    });
}

export function printTicket(data: PrintTicketParam) {
    const url = getMiddlewareUrl('ticket/otc/print');
    return handleProxyException(httpPost, 'printTicket', url, data);
}

export function getShift() {
    const url = getMiddlewareUrl('shift/otc');
    return handleProxyException(httpGet<Shift | undefined>, 'getShift', url);
}

export function getShiftStatisticsSummary() {
    const url = getMiddlewareUrl('shift/otc/statistics');
    return handleProxyException(httpGet<Summary[]>, 'getShiftStatisticsSummary', url);
}

export function getShiftTransactions() {
    const url = getMiddlewareUrl('shift/otc/transactions');
    return handleProxyException(httpGet<Transaction[]>, 'getShiftTransactions', url);
}

export function printShiftReport() {
    const url = getMiddlewareUrl('shift/otc/print');
    return handleProxyException(httpPost<void>, 'printShiftReport', url);
}

export function endShiftById(id: number, amount: number) {
    const url = getMiddlewareUrl(`shift/otc/${id}/end`);
    return handleProxyException(httpPut<Shift>, 'endShiftById', url, { amount });
}

export function startShift(fields: { amount: number }) {
    const url = getMiddlewareUrl('shift/otc/start');
    return handleProxyException(httpPost<Shift>, 'startShift', url, fields);
}

export function endShift(fields: { amount: number }) {
    const url = getMiddlewareUrl(`shift/otc/end`);
    return handleProxyException(httpPut<Shift>, 'endShift', url, fields);
}

export function changeShiftBalance(fields: { id: number; amount: number }) {
    const url = getMiddlewareUrl(`shift/otc/${fields.id}/balance`);
    return handleProxyException(httpPut<Shift>, 'changeShiftBalance', url, fields);
}

export function reprintVoucherByTransactionId(transactionId: string) {
    const url = getMiddlewareUrl('voucher/otc/reprint');
    return handleProxyException(httpPost, 'reprintVoucherByTransactionId', url, { transactionId });
}

export function printAndCashOutManuallyClosedSession(sessionId: string) {
    const url = getMiddlewareUrl(`session/otc/${sessionId}/print-and-cashout`);
    return handleProxyException(httpGet, 'printAndCashOutManuallyClosedSession', url);
}

export function reprintTicketByTransactionId(transactionId: string) {
    const url = getMiddlewareUrl('ticket/otc/reprint');
    return handleProxyException(httpPost, 'reprintTicketByTransactionId', url, { transactionId });
}

export function voidRetailTicket(ticketId: string, options: { isMaVoid: boolean } = { isMaVoid: false }) {
    const url = getMiddlewareUrl(`ticket/otc/${ticketId}/void`);
    return handleProxyException(httpPut<VoidedTicket[]>, 'voidRetailTicket', url, options);
}

export async function restartMachine() {
    const url = getMiddlewareUrl(`restart`);
    await handleProxyException(httpPost, 'restartMachine', url);
}

export async function shutdownMachine() {
    const url = getMiddlewareUrl(`shutdown`);
    await handleProxyException(httpPost, 'shutdownMachine', url);
}

export async function loginUser({ username, password }) {
    return handleProxyException(httpPost, 'loginUser', getMiddlewareUrl(`login/otc`), { username, password });
}

export async function depositToWebAccount(loyaltyId: string, amount: number, credentials?: Credentials) {
    const url = getMiddlewareUrl(`web-transfer/deposit`);
    return handleProxyException(httpPost, 'depositToWebAccount', url, {
        loyaltyId,
        amount,
        credentials,
    });
}

export async function withdrawFromWebAccount(loyaltyId: string, amount: number, credentials?: Credentials) {
    const url = getMiddlewareUrl(`web-transfer/otc/withdraw`);
    return handleProxyException(httpPost<WebTransferResponse>, 'withdrawFromWebAccount', url, {
        loyaltyId,
        amount,
        credentials,
    });
}

export function purchase(fields: {
    itemsToPurchase: ItemToPurchase[];
    cashFromClient: number;
    chipsFromClient: number;
    changeType: CartChangeType;
}) {
    const url = getMiddlewareUrl('shift/purchase/otc');
    return handleProxyException(httpPost, 'purchase', url, fields);
}

export function redeemVoucherById(id: string) {
    const url = getMiddlewareUrl(`voucher/otc/${id}/redeem`);
    return handleProxyException(httpPut<Voucher>, 'redeemVoucherById', url, {});
}

export function getRetailOtcTicketById(id: string) {
    const url = getMiddlewareUrl(`ticket/otc/${id}`);
    return handleProxyException(httpGet<TicketResponse>, 'getRetailOtcTicketById', url);
}

export function getRetailOtcVoucherById(id: string) {
    const url = getMiddlewareUrl(`voucher/otc/${id}`);
    return handleProxyException(httpGet<ExtendedVoucher>, 'getRetailOtcVoucherById', url);
}

export function redeemTicketById(id: string) {
    const url = getMiddlewareUrl(`ticket/otc/${id}/redeem`);
    return handleProxyException(httpPut<Ticket>, 'redeemTicketById', url, {});
}

export function purchaseVoid(loyaltyId: string) {
    const url = getMiddlewareUrl('shift/purchase/otc/void');
    return handleProxyException(httpPost<{ failedToManuallyVoidTickets: Ticket[] }>, 'purchaseVoid', url, {
        loyaltyId,
    });
}

export function getUnpaidItems() {
    const url = getMiddlewareUrl('shift/otc/unpaid-items');
    return handleProxyException(httpGet<ItemToPurchase[]>, 'getUnpaidItems', url);
}

export function purchaseEntries(data: ContestData) {
    const url = getMiddlewareUrl('shift/purchase/contest/otc');
    return httpPost<{
        error?: any;
        entriesToPurchase: ContestData['entriesToPurchase'];
        tickets?: Ticket[];
    }>(url, data);
}

export function getRetailW2gEventsByTicketId(data: { ticket_id: string; product: string }) {
    const url = getMiddlewareUrl('w2g/otc/events');
    return handleProxyException(
        httpPost<W2gEventDetailsResponse & { retail_ticket_ids: string[] }>,
        'getRetailW2gEventsByTicketId',
        url,
        data,
    );
}

export function getRetailW2gEventDetails(data: { eventId: string; product: string }) {
    const url = getMiddlewareUrl('w2g/otc/event');
    return handleProxyException(httpPost<any>, 'getRetailW2gEventDetails', url, data);
}

export function getOtcInquiryVouchers(filter: { [key: string]: number | string | number[] | string[] }) {
    const url = getMiddlewareUrl('voucher/otc/list');
    return handleProxyException(
        httpGet<ResponseWithSummary<VoucherReport>>,
        'getOtcInquiryVouchers',
        addQueryParamsToUrl(url, filter),
    );
}

export function getOtcInquiryTickets(filter: { [key: string]: number | string | number[] | string[] }) {
    const url = getMiddlewareUrl('ticket/otc/list');
    return handleProxyException(
        httpGet<ResponseWithSummary<TicketReport>>,
        'getOtcInquiryTickets',
        addQueryParamsToUrl(url, filter),
    );
}

export interface ContestData {
    entriesToPurchase: {
        id: string;
        legId: number;
        entryNumber: number;
        amount: number;
        isFree?: boolean;
        entryId?: string;
        predictions: {
            [market_id: number]: number;
        };
    }[];
    loyaltyId: string;
    contestId: number;
    location: string;
}

export interface TicketReceipt {
    singleTicketsAmount?: number;
    singleTicketsTotalPrice?: number;
    comboTicketsAmount?: number;
    comboTicketsTotalPrice?: number;
    systemTicketsAmount?: number;
    systemTicketsTotalPrice?: number;
    totalPrice: number;
    cashFromClient: number;
}

interface PrintTicketParam {
    ticketId: string;
    type?: TicketProduct;
}

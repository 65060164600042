import React, { useEffect } from 'react';
import { isTokenValid, logout } from 'services/auth';
import { ROLES, useKeycloak } from 'services/keycloak';
import { store, useStore } from 'stores/store';
import { logger } from 'services/logger';
import { ClientName } from '../../types/client';

interface Props {
    children: React.ReactNode;
    loginForm?: React.ReactNode;
}

const { OBSERVE } = ROLES.RETAIL_PROXY;

export function Keycloak({ children, loginForm = null }: Props) {
    const keycloak = useKeycloak();
    const [tokenCbAuth, setTokenCbAuth] = useStore(store.tokenCbAuth);
    const [tokenAuth, setTokenAuth] = useStore(store.tokenAuth);
    const [user] = useStore(store.boUser);
    const [{ CLIENT_NAME }] = useStore(store.environment);
    const isAuthenticated = Boolean(tokenCbAuth && tokenAuth && user);

    useEffect(() => {
        if (!loginForm) {
            keycloak.init().catch(error => logger.error('Keycloak', 'Keycloak', error));
        }
    }, []);

    useEffect(() => {
        if (tokenCbAuth && !isTokenValid(tokenCbAuth)) {
            setTokenCbAuth(undefined);
            store.isAuthenticated.set(false);
        }
    }, [tokenCbAuth]);

    useEffect(() => {
        if (tokenAuth && !isTokenValid(tokenCbAuth)) {
            setTokenAuth(undefined);
            store.isAuthenticated.set(false);
        }
    }, [tokenAuth]);

    if (isAuthenticated) {
        if ([ClientName.IVC, ClientName.WYNNBET, ClientName.STATION, ClientName.DEMO].includes(CLIENT_NAME)) {
            // Doing a logout if the user is trying to log in to a OTC and hasn't permission to log in to OTC
            if (loginForm && !keycloak.hasAccessForPermissions([OBSERVE])) {
                logout();
                return <>{loginForm}</>;
            }
            // Doing a logout if the user is trying to log in to a non-OTC and has permission to log in to OTC
            if (!loginForm && keycloak.hasAccessForPermissions([OBSERVE])) {
                logout();
                return <></>;
            }
        }
        return <>{children}</>;
    }
    return <>{loginForm}</>;
}

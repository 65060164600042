import styled, { css } from 'styled-components';

export default styled.div`
    .touch-input {
        &.ant-input {
            pointer-events: none !important;
        }
        input {
            pointer-events: none !important;
        }
    }
    ${props =>
        props.isModalInput &&
        css`
            .input-wrapper {
                user-select: none;
            }
            .touch-input {
                pointer-events: none !important;
            }
        `}
`;

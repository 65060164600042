import { mediaQuery } from 'services/media';
import styled from 'styled-components';

export default styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;

    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;

    > div {
        width: 100%;
    }

    .form {
        width: 100%;
    }

    .form-input {
        height: 5rem;
    }

    .login-btn {
        min-width: 7em;
        height: 5rem;
        font-weight: bold;
        margin-top: 5rem;
    }

    .keyboard {
        .hg-row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        .hg-button {
            width: auto !important;
            height: 5rem;

            &.hg-button-bksp {
                width: 132px;
                grid-column-start: 2;
                grid-column-end: 4;
            }
        }
        .simple-keyboard {
            padding: 0;
        }
    }

    @media ${mediaQuery.isDesktop} {
        .form-input {
            height: 6rem;
            font-size: 1.5rem;
        }

        .login-btn {
            min-width: 7em;
            height: 6rem;
            font-size: 1.5rem;
            margin-top: 6rem;
        }

        .keyboard {
            .hg-button {
                height: 6rem;
                font-size: 1.5rem;

                &.hg-button-bksp {
                    width: 226px;
                }
            }
            .simple-keyboard {
                padding: 0;
            }
        }
    }
`;

import styled from 'styled-components';

export default styled.div`
    .default-logo {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: absolute;
        flex-direction: column;
        overflow: hidden;
    }
    .customer-image {
        height: 100vh;
        width: 100vw;
    }
`;

import { ManualAcceptanceAggregateFilter } from '@staycool/bets-types/dist/_features/manualAcceptance/types';
import { DatabaseComboCardType, Market } from '@staycool/sports-types';
import { ResultsByOutcomeId } from '@staycool/sports-types/dist/features/resulting/types';
import { MarketWithOutcomesAndOddsValues } from '@staycool/sports-types/market';
import { MarketTypeGroup } from '@staycool/sports-types/market-type';
import { TradingViewMarket, TradingViewMatch, TradingViewTopMarketTypeV2 } from '@staycool/sports-types/trading-view';
import { AlarmListFilter, RiskReachedAlerts } from '@staycool/sportsbook-risk-types/alarms';
import { OutcomeRiskRow } from '@staycool/sportsbook-risk-types/outcome-risk';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { OddsLine } from 'microservices/sports/odds-lines';
import { Region } from 'microservices/sports/region';
import { ResponsibleBookmakerWatcher } from 'microservices/sports/responsible-bookmaker';
import { Sport } from 'microservices/sports/sport';
import { ALARM_BOOKMAKER_KEY } from 'services/alarm';
import { IS_DISPLAY_NOT_ACTIVE_ENABLED_KEY } from 'services/category';
import { IS_COMPACT_MARKET_CARDS_KEY, MARKET_CARD_ELEMENT_SIZE_KEY } from 'services/match';
import { OutcomeLimitsByMarketId } from 'services/outcome/outcome';
import { Bookmaker } from 'types/auth-bo';
import { CategoryType } from '../components/sportsbook/category/tree/types';
import {
    ProcessedAggregatedAcceptances,
    ProcessedManualAcceptanceAggregate,
} from '../services/manual-acceptance-aggregate/manual-acceptance-aggregate';
import { IS_AMERICAN_ODDS_FORMAT } from '../services/odds/odds';
import {
    TICKET_LIST_BACKGROUND_COLORS,
    TicketListBackgroundColorSettings,
    TicketListBackgroundColorSettingsDefaults,
} from '../services/ticket/ticket';
import { Client } from '../types/client';
import { TradingViewCategories } from '../types/trading';
import { createStores } from './utils';
import { DatabaseAggregatedVoidTicket } from '@staycool/bets-types';
import { DatabaseOdds } from '@staycool/odds-types';
import { BetbuilderComboCardMatch } from '../microservices/sports/openbet';
import { MatchDataById } from '../microservices/sports/combo-card';

export const sportsbook = createStores({
    alarm: createStores({
        activeWatchers: [] as ResponsibleBookmakerWatcher[],
        list: [] as RiskReachedAlerts[],
        filteredPendingAlarms: [] as RiskReachedAlerts[],
        filteredBookmakerIds: (JSON.parse(localStorage.getItem(ALARM_BOOKMAKER_KEY) as string) as number[]) || [],
        filter: {} as Partial<AlarmListFilter>,
        ignoreResponsibleBookmakers: false,
        notificationAlarmsOutcomesIds: [] as string[],
    }),
    bookmakers: [] as Bookmaker[],
    isDisplayNotActiveEnabled: localStorage.getItem(IS_DISPLAY_NOT_ACTIVE_ENABLED_KEY) === 'true',
    isOddsFormatAmerican: localStorage.getItem(IS_AMERICAN_ODDS_FORMAT) === 'true',
    isFeederConnected: false,
    marketCardStyle: {
        isCompactMarketCards: !(localStorage.getItem(IS_COMPACT_MARKET_CARDS_KEY) === 'false'),
        elementSize: (localStorage.getItem(MARKET_CARD_ELEMENT_SIZE_KEY) || 'middle') as SizeType,
    },
    matchView: createStores({
        oddsLines: [] as OddsLine[],
        resultsByOutcomeId: {} as ResultsByOutcomeId,
    }),
    aggregatedTicketAcceptances: createStores({
        filteredPendingTickets: [] as ProcessedAggregatedAcceptances[],
        activeWatchers: [] as ResponsibleBookmakerWatcher[],
        filter: {} as ManualAcceptanceAggregateFilter,
        filteredBookmakerIds:
            (JSON.parse(localStorage.getItem('maResponsibleBookmakersVersion2') as string) as number[]) || [],
        pendingRequestsCount: 0,
        filteredOutTicketsCount: 0,
        ignoreResponsibleBookmakers: false,
        manualAcceptancesList: [] as ProcessedManualAcceptanceAggregate[],
        voidTicketsList: [] as DatabaseAggregatedVoidTicket[],
    }),
    isCategoryTreeOpen: false as boolean,
    treeSelectedCategory: undefined as undefined | { id: number; depth?: number; name?: string },
    isCategoryAndMatchSearchModalVisible: false,
    topMarketTypesByCategoryId: {} as Record<number, number[]>,
    sports: [] as Sport[],
    regions: [] as Region[],
    marketTypeGroups: [] as MarketTypeGroup[],
    netrisk: createStores({
        marketsById: {} as Record<number, Market.GetOpenMatchMarketsWithExtras>,
        oddsByOutcomeId: {} as Record<number, DatabaseOdds>,
        riskByOutcomeId: {} as Record<number, OutcomeRiskRow>,
        limitsByMarketId: {} as OutcomeLimitsByMarketId,
        alarms: [] as Partial<
            OutcomeRiskRow & Pick<Market.GetOpenMatchMarketsWithExtras, 'market_type_id' | 'market_type_group_id'>
        >[],
    }),
    trading: createStores({
        isLineModalOpen: false,
        selectedCategory: {} as TradingViewCategories,
        selectedMatch: {} as TradingViewMatch,
        selectedMarketType: {} as TradingViewTopMarketTypeV2,
        selectedMarket: {} as TradingViewMarket,
        asbMainMarketByMatchId: {} as Record<number, MarketWithOutcomesAndOddsValues | undefined>,
        outcomeRiskByOutcomeId: {} as Record<number, OutcomeRiskRow>,
        netRiskInfo: {
            type: 'all',
            filter: {},
        },
        isLoading: false,
        filter: {
            matchIds: [] as number[],
            categoryIds: [] as number[],
            marketTypesMap: {} as Record<number, number[]>,
            includeLivebetMarkets: false,
        },
        mainLineMarketIndex: {} as Record<number, Record<number, number>>,
        oddsValueByOutcomeId: {} as Record<number, DatabaseOdds['value'] | undefined>,
    }),
    ticketList: createStores({
        activeWatchers: {} as Record<number, number[]>,
        isResponsibleBookmakerFiltered: true as boolean,
    }),
    favouriteCategories: [] as CategoryType[],
    ticketListBackgroundColorRanges: (JSON.parse(localStorage.getItem(TICKET_LIST_BACKGROUND_COLORS) || 'null') ??
        TicketListBackgroundColorSettingsDefaults) as TicketListBackgroundColorSettings,
    coreClients: undefined as Client[] | undefined,
    comboCard: createStores({
        types: [] as DatabaseComboCardType[],
        isBetbuilderMarkets: false,
        shouldDisplayMarketsAlert: false,
        matchDataById: {} as MatchDataById,
        betbuilderMatchDataById: {} as Record<number, BetbuilderComboCardMatch>,
    }),
});

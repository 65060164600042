import { RetailOtcLogin } from 'components/retail-otc/login/RetailOtcLogin';
import { SplashScreen } from 'components/splash-screen/SplashScreen';
import { System } from 'components/system/System';
import { Keycloak } from 'components/keycloak/Keycloak';
import React, { useEffect, useState } from 'react';
import { initKeycloak } from 'services/keycloak';
import { logger } from 'services/logger';
import { useRouter, getRootRouteName, getKeyFromRoute, getRoute } from 'services/router';
import { loadEnvironment } from 'services/util';
import { store } from 'stores/store';
import { useStore } from 'stores/utils';
import { App } from './App';
import { initSentry } from './services/sentry';
import { loadFeatures } from './services/features';
import theme from 'services/theme';
import { GlobalStyles } from 'style/global';
import { ConfigProvider } from 'antd';
import { ROUTES } from 'routes';

export function Bootstrap() {
    const [isBootstrapped, setIsBootstrapped] = useStore(store.isBootstrapped);
    const [isAuthenticated] = useStore(store.isAuthenticated);
    const [isFeaturesLoaded, setIsFeaturesLoaded] = useState(false);
    const { pathname } = useRouter();
    const currentRootRouteName = getRootRouteName(pathname);
    const isRetailCustomerFaceRoute = pathname.includes(getKeyFromRoute(getRoute(ROUTES.retailOtc.customerFace)));
    const isRetailOtcRoute = ['retail-otc'].includes(currentRootRouteName);

    useEffect(() => {
        bootstrap();
    }, []);

    useEffect(() => {
        initFeaturesLoad();
    }, [isAuthenticated]);

    async function initFeaturesLoad() {
        if (isAuthenticated) {
            await loadFeatures();
            setIsFeaturesLoaded(true);
        }
    }

    async function bootstrap() {
        try {
            await loadEnvironment();
            await initSentry();
            initKeycloak();
        } catch (error) {
            logger.error('Bootstrap', 'bootstrap', error);
        }
        setIsBootstrapped(true);
    }

    if (isBootstrapped) {
        if (isRetailCustomerFaceRoute) {
            return (
                <ConfigProvider componentSize="large" theme={theme}>
                    <GlobalStyles isRetailOtcRoute={isRetailOtcRoute} />
                    <App isRetailOtcRoute={isRetailOtcRoute} />
                </ConfigProvider>
            );
        }
        return (
            <ConfigProvider componentSize="large" theme={theme}>
                <GlobalStyles isRetailOtcRoute={isRetailOtcRoute} />
                <Keycloak loginForm={isRetailOtcRoute ? <RetailOtcLogin /> : null}>
                    {isFeaturesLoaded ? (
                        <>
                            <App isRetailOtcRoute={isRetailOtcRoute} />
                            <System />
                        </>
                    ) : (
                        <SplashScreen />
                    )}
                </Keycloak>
            </ConfigProvider>
        );
    }
    return <SplashScreen />;
}

import { FoCategoryMatchMarket, FoSidebetsMarketGroupMarket } from '@staycool/sbgate-types';
import { DatabaseSequence, MarketStatus, MarketViewType } from '@staycool/sports-types';
import { DatabaseMarket, DatabaseOutcome, FixtureProvider } from '@staycool/sports-types/dist/types';
import { DatabaseOdds } from '@staycool/odds-types';
import { MarketWithOutcomes, MatchMarket } from '@staycool/sports-types/dist/features/market/types';
import { BoMatch } from '@staycool/sports-types/dist/features/match/types';
import { ResultStatus } from '@staycool/sports-types/dist/features/resulting/types';
import { RetailMatchMarket } from '@staycool/sports-types/dist/features/search/types';
import { TradingViewMarket, TradingViewOutcomeV2 } from '@staycool/sports-types/trading-view';
import { message, notification } from 'antd';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import { Odds, saveOdds } from 'microservices/sports-decider';
import { MarketTypeRow } from 'microservices/sports/market-type';
import { getTeamPlayers, MarketPlayerRow } from 'microservices/sports/players';
import { MarketTeamRow } from 'microservices/sports/teams';
import { useMemo } from 'react';
import { awayPlaceholders, getOutcomesByOrder } from 'services/outcome/outcome';
import { MissingResultMarket } from 'services/resulting';
import { getStoreValue, store } from 'stores/store';
import { getBetradarMarketOddsSource } from '../microservices/betradar';
import { getCoreMarketOddsSource } from '../microservices/client-receiver';
import { sendRefFeedOddsToCore } from '../microservices/reference-feed/odds';
import { OddsStatus } from '../microservices/sb-odds';
import { deleteMarket, updateMarket, updateOddsProvider } from '../microservices/sports/market';
import {
    displayMarketsIdsStore,
    isCreateNewLineModalVisibleStore,
    marketByMarketIdStore,
    newLineMarketIdStore,
    newLineMarketInitialValueStore,
} from '../stores/matchPage';
import { MatchStatus } from '../types/match';
import { isFeatureAvailable } from './features';
import { logger } from './logger';
import { FormattedParlayMarketBO, ParlayMarketOutcomeBO } from './sports/parlay-card/pdf-generator';
import { MarketTypeGroup } from '@staycool/sports-types/dist/features/market-type/types';

export const marketStatusToOddsStatus = {
    INITIAL: 'OPEN',
    OPEN: 'OPEN',
    DISABLED: 'SUSPENDED',
    SUSPENDED: 'SUSPENDED',
    CLOSED: 'CLOSED',
    RESULTED: 'CLOSED',
    CANCELLED: 'CLOSED',
};

export const marketStatuses = [
    'CANCELLED',
    'CLOSED',
    'DISABLED',
    'INITIAL',
    'OPEN',
    'RESULTED',
    'SUSPENDED',
    'SUSPENDED_RISK',
];

export function processMarkets(
    match: BoMatch,
    marketsByMarketTypeGroup: Record<number, MatchMarket[]>,
    oddsByOutcomeId: Record<number, Record<string, any>>,
    marketTypeGroups: MarketTypeGroup[],
) {
    let markets = [] as ProcessedMatchMarket[];
    const marketTypeGroupsById = keyBy(marketTypeGroups, 'id');
    let marketGroupeFilter = {} as Record<string, boolean>;

    if (match.has_bet_builder_market) {
        marketGroupeFilter = { 'Bet Builder': false };
    }

    const urlParams = new URLSearchParams(window.location.search);
    Object.entries(marketsByMarketTypeGroup).forEach(([marketTypeGroupId, groupMarkets]) => {
        let isCorrespondingMarketInUrl = false;
        const marketInUrl = urlParams.get('market');
        if (marketInUrl) {
            isCorrespondingMarketInUrl = groupMarkets.findIndex(market => market.id === +marketInUrl) !== -1;
        }

        const groupName = marketTypeGroupsById[marketTypeGroupId]?.name;
        marketGroupeFilter = { ...marketGroupeFilter, [groupName]: isCorrespondingMarketInUrl };
        const processedMarkets = groupMarkets.map(market => processMarket(match, market, oddsByOutcomeId, groupName));
        markets = [...markets, ...processedMarkets];
    });

    const marketGroupNamesByPosition = Object.keys(marketGroupeFilter).map(groupName => {
        const marketGroupId = marketTypeGroups.find(group => group.name === groupName)?.id;

        const positionIds =
            marketGroupId && marketsByMarketTypeGroup[marketGroupId]
                ? marketsByMarketTypeGroup[marketGroupId].map(market => Number(market.market_type.position))
                : [];

        const filteredPositionIds = positionIds.filter(Boolean);

        return {
            groupName,
            position: filteredPositionIds.length ? Math.min(...filteredPositionIds) : null,
        };
    });

    const sortedMarketGroupFilter = {};

    for (const { groupName } of orderBy(marketGroupNamesByPosition, ['position'])) {
        sortedMarketGroupFilter[groupName] = marketGroupeFilter[groupName];
    }

    return { markets, marketGroupeFilter: sortedMarketGroupFilter };
}

export function processMarket(
    match: BoMatch,
    market: MatchMarket,
    oddsByOutcomeId: Record<number, Record<string, any>>,
    groupName: string,
) {
    const homeTeamName = match.home_team_obj?.name || '';
    const awayTeamName = match.away_team_obj?.name || '';

    const placeholdersToReplace = [
        { placeholder: '[home]', value: homeTeamName },
        { placeholder: '[Player 1]', value: homeTeamName },
        { placeholder: '[away]', value: awayTeamName },
        { placeholder: '[Player 2]', value: awayTeamName },
    ];

    market.name = replaceMarketNameSequences(market.name, market.sequence);

    const marketWithFormattedNames = placeholdersToReplace.reduce(
        (market, { placeholder, value }) => formatMarketAndOutcomeNames(market, value, placeholder),
        market,
    );
    const processedOutcomes =
        marketWithFormattedNames.outcomes?.map(outcome => {
            const odds = oddsByOutcomeId?.[outcome.id] || {
                market_id: market.id,
                match_id: market.match_id,
                outcome_id: outcome.id,
                value: undefined,
            };
            return { ...outcome, odds };
        }) || [];
    const marginKey = calculateOutcomesMarginKey(processedOutcomes);

    return {
        ...marketWithFormattedNames,
        margin_key: marginKey,
        outcomes: getOutcomesByOrder(processedOutcomes, market.view_type, market.outcomes_order_by),
        realMarginKey: marginKey || 0,
        groupName,
    } as ProcessedMatchMarket;
}

function calculateOutcomesMarginKey(outcomes: ProcessedMatchMarketOutcome[]) {
    if (!outcomes?.every(outcome => outcome.odds.value)) {
        return;
    }
    return calculateMarginKey(outcomes.map(outcome => outcome.odds.value as number));
}

export function calculateMarginKey(oddsValues: number[]) {
    let marginKey = oddsValues.reduce((marginKey, odds) => {
        return marginKey + 1 / odds;
    }, 0);
    marginKey *= 100;
    return round(marginKey, 2);
}

export function formatMarketAndOutcomeNames(market: MatchMarket, name: string, placeholder: string) {
    const outcomes = market.outcomes?.map(outcome => {
        return {
            ...outcome,
            name: findAndReplace(outcome.name, name, placeholder),
        };
    });
    return { ...market, outcomes, name: findAndReplace(market.name, name, placeholder) };
}

export function formatMarketNames(market: MarketTypeRow, name: string, placeholder: string) {
    return { ...market, name: findAndReplace(market.name, name, placeholder) };
}

export function findAndReplace(str: string, name: string, placeholder: string) {
    if (!str || !name) {
        return str;
    }
    const pattern = placeholder.toLowerCase().replace('[', '\\[').replace(']', '\\]');
    const re = new RegExp(pattern, 'gi');
    return str.replace(re, name);
}

export async function getMatchHomeAndAwayPlayers(match: BoMatch) {
    if (!(match.home_team && match.away_team)) {
        return Promise.resolve(undefined);
    }
    const players = await getTeamPlayers([match.home_team, match.away_team]);
    const homeTeamPlayers = orderBy(
        players?.filter(p => p.team_id === match.home_team),
        'name',
    );
    const awayTeamPlayers = orderBy(
        players?.filter(p => p.team_id === match.away_team),
        'name',
    );
    return { homeTeamPlayers, awayTeamPlayers };
}

export function hasMissingOdds(outcomes: ProcessedMatchMarketOutcome[]) {
    return outcomes.some(({ odds }) => !odds.value || isNaN(odds.value));
}

export const viewTypes: MarketViewType[] = [
    'simple',
    'line',
    'team_outright',
    'player_outright',
    'exact_score',
    'ht_ft',
    'generic',
    'generic_line',
    'variable',
    'simple_single',
    'teaser',
    'parlay',
    'paper_parlay',
];

export function isHalfTimeFullTime(market: { view_type: MarketViewType }) {
    return market.view_type === 'ht_ft';
}

export function isMarketInPlay(market: ProcessedMatchMarket, matchStatus: MatchStatus) {
    return market[matchStatus === 'LIVE' ? 'in_play' : 'future_in_play'];
}

export function isMarketDisabled(market: { market_status: MarketStatus }) {
    return ['DISABLED', 'CANCELLED', 'SUSPENDED', 'SUSPENDED_RISK'].includes(market?.market_status);
}

export function getLine(
    outcome: CategoryMatchMarketOutcome | ParlayMarketOutcomeBO,
    market: FoCategoryMatchMarket | FoSidebetsMarketGroupMarket | RetailMatchMarket | FormattedParlayMarketBO,
    forcedLine: string = '',
) {
    const firstTeamName = market?.team_names?.length === 2 ? market.team_names[0] : undefined;
    const lastTeamName = market?.team_names?.length === 2 ? market.team_names[1] : undefined;
    if (!shouldDisplayMarketLine(market) || !outcome || ['Over', 'Under'].includes(outcome.result_key) || !market) {
        return;
    }

    const firstLine =
        Number(market.raw_line) > 0 ? `+${addZero(Number(market.raw_line))}` : addZero(Number(market.raw_line));
    const secondLine =
        Number(market.raw_line) * -1 > 0
            ? `+${addZero(Number(market.raw_line) * -1)}`
            : addZero(Number(market.raw_line) * -1);

    if (['[Home]', '[Player 1]', firstTeamName].includes(outcome.result_key)) {
        return forcedLine || firstLine;
    }
    if (['[Away]', '[Player 2]', lastTeamName].includes(outcome.result_key)) {
        return forcedLine || secondLine;
    }

    if (market.outcomes.length === 2) {
        if (market.outcomes[0].id === outcome.id) {
            return forcedLine || firstLine;
        }
        if (market.outcomes[1].id === outcome.id) {
            return forcedLine || secondLine;
        }
    }
}

export function addZero(rawLine: number) {
    return rawLine % 1 !== 0 ? rawLine : `${rawLine}.0`;
}

export type CategoryMatchMarketOutcome =
    | FoCategoryMatchMarket['outcomes'][0]
    | FoSidebetsMarketGroupMarket['outcomes'][0];
export type MatchMarketOutcome = MatchMarket['outcomes'][0];

export interface ProcessedMatchMarketOutcome extends MatchMarketOutcome {
    odds: Partial<DatabaseOdds>;
}

export interface ProcessedMatchMarket extends MatchMarket {
    outcomes: ProcessedMatchMarketOutcome[];
    cancel_reason: string;
    target_margin: number;
    is_player_home_team?: boolean;
    realMarginKey: number;
    groupName: string;
    isResulted?: boolean;
    managed_market?: boolean;
    canOverrideCoreOdds?: boolean;
    odds_settings: {
        odds_ladder_id: number | null;
        odds_line_id: number | null;
    };
    is_available_for_retail?: boolean;
    team_names: string[] | null;
}

export function getMarketTypeGroupName(id: string) {
    const marketTypeGroups = getStoreValue(store.sportsbook.marketTypeGroups);
    const marketTypeGroup = marketTypeGroups.filter(mtg => {
        return mtg.id === Number(id);
    });
    if (marketTypeGroup.length > 0) {
        return marketTypeGroup[0].name;
    }
    return id;
}

export const mainLineAlgoByOutcomeLen = {
    2: oddsDiff,
    3: (outcomes: CategoryMatchMarketOutcome[] | TradingViewOutcomeV2, oddsByOutcomeId: Record<number, DatabaseOdds>) =>
        (oddsByOutcomeId[outcomes[1].id] || {}).value || 9999,
};

export function isHalfLine(line: number) {
    return Math.abs(line % 1) === 0.5;
}

export function oddsDiff(outcomes: CategoryMatchMarketOutcome[], oddsByOutcomeId: Record<number, DatabaseOdds>) {
    const oddsHome = oddsByOutcomeId[outcomes[0].id]?.value ?? Infinity;
    const oddsAway = oddsByOutcomeId[outcomes[1].id]?.value ?? Infinity;
    const delta = oddsHome - oddsAway;
    return isNaN(delta) ? Infinity : Math.abs(delta);
}

export interface MarketBase {
    id: number;
    status: string;
    line: string | number;
    view_type: string;
    outcomes: any[];
    raw_line?: number;
}

export function findMainLineMarket<T extends MarketBase | TradingViewMarket>(
    markets: T[],
    viewType: string,
    oddsByOutcomeId: Record<number, DatabaseOdds>,
    shouldPreferHalfLines: boolean,
): T {
    const outcomesCount = markets[0]?.outcomes?.length;
    if (viewType !== 'line' || !outcomesCount) {
        return markets[0];
    }

    const sortMainLineAlgo = mainLineAlgoByOutcomeLen[outcomesCount];
    if (!sortMainLineAlgo) {
        return markets[0];
    }

    try {
        const marketsSortedByMainLine = handleMainLineSort(sortMainLineAlgo, markets, oddsByOutcomeId);
        if (!shouldPreferHalfLines) {
            return marketsSortedByMainLine[0];
        }

        const halfLineMarkets = marketsSortedByMainLine.filter(market =>
            isHalfLine(Number(market.raw_line || market.line)),
        );
        return halfLineMarkets[0] || marketsSortedByMainLine[0];
    } catch (err) {
        logger.error('MarketService', 'findMainLineMarket', err);
        return markets[0];
    }
}

function handleMainLineSort<T extends MarketBase | TradingViewMarket>(
    sortingAlgo: any,
    markets: T[],
    oddsByOutcomeId: Record<number, DatabaseOdds>,
): T[] {
    const primarySort = (market: T) => sortingAlgo(market.outcomes, oddsByOutcomeId);
    const secondarySort = (market: T) => isHalfLine(Number(market.raw_line || market.line));
    const thirdSort = (market: T) => Math.abs(Number(market.raw_line || market.line));
    const quaternarySort = (market: T) => Number(market.raw_line || market.line);
    return orderBy(markets, [primarySort, secondarySort, thirdSort, quaternarySort], ['asc', 'desc', 'asc', 'desc']);
}

export function getLineToDisplay(
    market: ProcessedMatchMarket | MissingResultMarket | (TradingViewMarket & { team_names?: string[] }),
    outcome: ProcessedMatchMarketOutcome | DatabaseOutcome | TradingViewOutcomeV2,
) {
    const { view_type, line, team_names } = market;
    const { result_key } = outcome;

    const isMarketTotals = ['over', 'under'].includes(result_key.toLowerCase());
    if (isMarketTotals) return ` [${line}]`;

    const shouldDisplayLine = view_type === 'line' && line;
    if (!shouldDisplayLine) return '';

    const reversedLine = line * -1;
    const originalLine = line > 0 ? `+${line}` : line;
    const oppositeLine = reversedLine > 0 ? `+${reversedLine}` : reversedLine;

    const isSeriesSecondTeam = team_names && team_names[1] === result_key;
    const isAwayTeam =
        awayPlaceholders.includes(result_key.toLowerCase()) ||
        (market.outcomes &&
            market.outcomes.findIndex(({ name }) => name.toLowerCase().includes(result_key.toLowerCase())) ===
                market.outcomes.length - 1);

    const shouldReverseLine = isAwayTeam || isSeriesSecondTeam;
    return ` [${shouldReverseLine ? oppositeLine : originalLine}]`;
}

export function handlePlaceholderBiggerThanOne(
    selectedMarketType: MarketTypeRow,
    selectedParticipants: MarketPlayerRow[] | MarketTeamRow[],
    participantPlaceholder: string,
    participantsIdsKey: string,
    participantTemplate: string,
) {
    const participantsIds: number[] = [];
    const clonedSelectedMarketType = { ...selectedMarketType } as MarketTypeRow;

    if (selectedMarketType[participantPlaceholder] !== selectedParticipants.length) {
        notification.error({ message: `Selected participants and placeholder count doesn't match!` });
        return;
    }

    selectedParticipants.forEach(participant => {
        clonedSelectedMarketType.name = clonedSelectedMarketType.name.replace(participantTemplate, participant.name);
        participantsIds.push(participant.id);
    });

    clonedSelectedMarketType[participantsIdsKey] = participantsIds;

    return clonedSelectedMarketType;
}

export async function updateOddsSource(marketId: number, provider: string) {
    const { ENVIRONMENT } = getStoreValue(store.environment);

    if (provider === 'lsports' && ENVIRONMENT !== 'stage') {
        notification.error({
            message: `Provider ${provider} is not configured for ${process.env.ENVIRONMENT} environment yet`,
        });
        return false;
    }
    try {
        await updateOddsProvider(marketId, provider);
        marketByMarketIdStore.set(state => {
            state[marketId].provider_name = provider as FixtureProvider;
        });
        if (provider === 'lsports') {
            await sendRefFeedOddsToCore(marketId);
        }
        message.success(`Odds source updated to ${provider.startsWith('core') ? 'core' : provider}`);
        return true;
    } catch (error) {
        logger.log('updateOddsSource', error);
        notification.error({
            message: `Failed to update odds source to ${provider}`,
        });
        return false;
    }
}

export const onDemandViewTypes: string[] = ['teaser', 'parlay', 'paper_parlay'];

export function shouldDisplayMarketLine(market: {
    view_type?: string;
    line?: number | string;
    market_type?: ProcessedMatchMarket['market_type'];
    market_type_name?: string;
}) {
    if (!['line', 'generic_line', ...onDemandViewTypes].includes(market.view_type || '')) {
        return false;
    }
    if (['line', 'generic_line', 'teaser'].includes(market.view_type || '')) {
        return true;
    }

    const marketTypeName = (market.market_type_name || market.market_type?.name)?.toLowerCase() || '';
    const isHandicap = marketTypeName.includes('handicap');
    const isTotals = marketTypeName.includes('total');
    return isHandicap || isTotals;
}

export function formatMarketLine(market: { marketLine: number; marketTypeName: string }): string {
    const isHandicapMarket = market.marketTypeName.toLowerCase().includes('handicap');
    if (isHandicapMarket) {
        return market.marketLine > 0 ? ` ${Math.abs(market.marketLine)} - 0` : ` 0 - ${Math.abs(market.marketLine)}`;
    }
    return ` ${market.marketLine}`;
}

export function replaceMarketNameSequences(marketName: string, sequence: DatabaseSequence | null | undefined) {
    if (sequence) {
        Object.entries(sequence).forEach(([key, value]) => {
            marketName = marketName.replace(`[${key}]`, (value || 0).toString());
        });
    }
    return marketName;
}

export async function updateMarketAndOddsStatus(
    oldStatus: string,
    marketId: number,
    matchId: number,
    outcomes: {
        outcome_id: number;
        value: number;
    }[],
) {
    const newStatus = oldStatus === 'OPEN' ? 'DISABLED' : 'OPEN';
    await updateMarket(marketId, { status: newStatus });
    if (newStatus === 'OPEN') {
        const oddsList = outcomes.map(
            outcome =>
                ({
                    match_id: matchId,
                    market_id: marketId,
                    outcome_id: outcome.outcome_id,
                    value: outcome.value,
                    status: 'OPEN',
                } as Odds),
        );
        await saveOdds(oddsList);
    }
    return newStatus;
}

export function useSmartMarketUniqueNameByMarketId(
    showUniqeName: number | boolean,
    markets: FoSidebetsMarketGroupMarket[],
    uniqueSequences: { key: string; translation: string }[],
    isLineMarket: boolean,
) {
    return useMemo(() => {
        if (!showUniqeName) {
            return {};
        }
        const marketUniquNames = markets.map(market => {
            return [market.id, getUniqueMarketName(market, uniqueSequences, isLineMarket)];
        });
        return Object.fromEntries(marketUniquNames);
    }, [showUniqeName, markets]);
}

export function getUniqueMarketName(
    market: FoSidebetsMarketGroupMarket,
    uniqueSequences: { key: string; translation: string }[],
    isLineMarket: boolean,
) {
    const teamOrPlayers = (market.team_names || market.player_names || []).join(' - ');
    const sequences = (uniqueSequences || [])
        .map(({ key, translation }) => `${translation}: ${market.sequence?.[key]}`)
        .join(', ');
    const line = isLineMarket ? market.line : '';
    return `${sequences} ${teamOrPlayers} ${line}`;
}

export interface OutcomeWithOdds extends DatabaseOutcome {
    result_status?: ResultStatus;
    market?: number;
    odds: {
        id: number;
        market_id: number;
        match_id: number;
        outcome_id: number;
        value: number;
        status: OddsStatus;
    };
}

export interface PreparedMatchMarket extends DatabaseMarket {
    outcomes: Record<string, OutcomeWithOdds>;
    outcomeList?: Array<OutcomeWithOdds>;
}

export function mapOddsToMarket(
    match: BoMatch,
    odds: Record<number, DatabaseOdds> | undefined,
    market: MarketWithOutcomes,
): PreparedMatchMarket {
    const [homeTeamName, awayTeamName] = [match.home_team_obj?.name ?? '', match.away_team_obj?.name ?? ''];
    const marketOutcomes = market.outcomes?.reduce(
        (dict, outcome) => ({
            ...dict,
            [outcome.result_key]: {
                ...outcome,
                name: replaceName(outcome.name),
                odds: odds?.[outcome.id] ?? {
                    market_id: market.id,
                    match_id: market.match_id,
                    outcome_id: outcome.id,
                    value: null,
                },
            },
        }),
        {},
    );

    return {
        ...market,
        name: replaceName(market.name),
        outcomes: marketOutcomes,
    };

    function replaceName(name: string) {
        return name.replace(/\[home\]|\[Player 1\]/gi, homeTeamName).replace(/\[away\]|\[Player 2\]/gi, awayTeamName);
    }
}

export function isKindaOutright(market: PreparedMatchMarket) {
    return ['player_outright', 'team_outright', 'variable'].includes(market.view_type);
}

export function shouldDisplayMarketCancelButton(marketStatus: MarketStatus) {
    return !['INITIAL', 'CANCELLED', 'RESULTED'].includes(marketStatus);
}

export function shouldDisplayMarketRemoveButton(market: ProcessedMatchMarket) {
    return market.status === 'INITIAL' && !onDemandViewTypes.includes(market.view_type);
}

export function shouldDisplayNewLineButton(market: ProcessedMatchMarket) {
    return market.view_type === 'line' && ['OPEN', 'INITIAL', 'DISABLED', 'SUSPENDED'].includes(market.status);
}

export function openCreateNewLineModal(marketId: number, marketLine: number) {
    isCreateNewLineModalVisibleStore.set(true);
    newLineMarketIdStore.set(marketId);
    newLineMarketInitialValueStore.set(marketLine);
}

export async function toggleMarketStatus(matchId: number, market: ProcessedMatchMarket): Promise<void> {
    try {
        const { status, id, outcomes } = market;
        const newStatus = await updateMarketAndOddsStatus(
            status,
            id,
            matchId,
            outcomes.map(outcome => ({ outcome_id: outcome.id, value: outcome.odds.value as number })),
        );
        marketByMarketIdStore.set(state => {
            state[market.id].status = newStatus;
        });
        message.success('Market status updated');
    } catch (error) {
        logger.log('MarketService', 'toggleMarketStatus', error);
        notification.error({
            message: 'Failed to update market status',
        });
    }
}

export async function setOddSource(market: ProcessedMatchMarket, provider: FixtureProvider) {
    let updatedProvider = provider;

    if (updatedProvider === market.provider_name) {
        return;
    }

    if ((isFeatureAvailable('clientCore') || !isFeatureAvailable('core')) && ['core', 'betradar'].includes(provider)) {
        let oddsSource;
        switch (updatedProvider) {
            case 'betradar':
                oddsSource = await getBetradarMarketOddsSource(market.id);
                break;
            case 'core':
                oddsSource = await getCoreMarketOddsSource(market.id);
                break;
        }
        if (!oddsSource) {
            return;
        }
        updatedProvider = oddsSource.provider_name as FixtureProvider;
    }

    await updateOddsSource(market.id, updatedProvider);
}

export async function removeMarketAndUpdateStore(marketId: number, marketName: string) {
    try {
        await deleteMarket(marketId);
        message.success(`Market deleted: ${marketName}`);
        displayMarketsIdsStore.set(state => state.filter(id => id !== marketId));
        marketByMarketIdStore.set(state => {
            delete state[marketId];
        });
    } catch (error) {
        message.error('Failed to remove market');
        logger.log('MarketService', 'removeMarketAndUpdateStore', error);
    }
}

export interface SortCriterion {
    fieldKey: 'market_type_group_name' | 'market_type_name' | 'playerTeamName' | 'name' | 'line';
    direction: 'ASC' | 'DESC' | 'OFF';
}

export function sortDisplayedMarkets(originalDisplayMarkets: MissingResultMarket[], newCriteria: SortCriterion[]) {
    return [...originalDisplayMarkets].sort((a, b) => {
        for (const criterion of newCriteria) {
            if (criterion.direction === 'OFF') {
                continue;
            }

            let aValue: string | number;
            let bValue: string | number;

            if (criterion.fieldKey === 'playerTeamName') {
                aValue = a.player_names.length > 0 ? a.player_names[0] : a.team_names[0];
                bValue = b.player_names.length > 0 ? b.player_names[0] : b.team_names[0];
                if (aValue === undefined && bValue) {
                    return 1;
                } else if (aValue && bValue === undefined) {
                    return -1;
                }
            } else {
                aValue = a[criterion.fieldKey];
                bValue = b[criterion.fieldKey];
            }

            if (aValue < bValue) {
                return criterion.direction === 'ASC' ? -1 : 1;
            }
            if (aValue > bValue) {
                return criterion.direction === 'ASC' ? 1 : -1;
            }
        }
        return 0;
    });
}

export interface OutcomeLimitsPreview {
    outcomeName: string;
    userLimit: string;
    totalLimit: string;
}

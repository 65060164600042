import { Button, Col, Row, Space, Spin } from 'antd';
import { UiKeyboard } from 'components/ui/keyboard/UiKeyboard';
import useLocalStorage from 'hooks/useLocalStorage';
import React, { useEffect, useState } from 'react';
import {
    emitKeycloakTokens,
    initLocalStorageListenerForKeycloakTokens,
    STORAGE_KEY_KEYCLOAK_TOKENS,
    Tokens,
    useKeycloak,
} from 'services/keycloak';
import { logger } from 'services/logger';
import { getStoreValue, store, useStore } from 'stores/store';
import Wrapper from './styles';
import { getKeyFromRoute, getRoute, useRouter } from 'services/router';
import { RetailOtcCustomerFaceBanner } from 'components/retail-otc/customer-face/banner/RetailOtcCustomerFaceBanner';
import { CustomerFaceRoute, CustomerFaceLocalStorageKey } from 'types/retail-otc';
import { initialCustomerFaceState } from 'services/retail';
import { ItemToPurchase } from '@staycool/retail-types/otc';
import { RetailOtcTouchInput } from '../touch/input/RetailOtcTouchInput';
import { loginUser } from 'microservices/retail-middleware';
import { ROUTES } from 'routes';

export function RetailOtcLogin() {
    const { CLIENT_NAME } = getStoreValue(store.environment) || {};
    const { init } = useKeycloak();
    const [tokenCbAuth] = useStore(store.tokenCbAuth);
    const [tokenAuth] = useStore(store.tokenAuth);
    const [user] = useStore(store.boUser);
    const [isLoading, setIsLoading] = useState(false);
    const [currentInput, setCurrentInput] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });
    const [tokens] = useLocalStorage(STORAGE_KEY_KEYCLOAK_TOKENS, {} as Tokens);
    const isAuthenticated = Boolean(tokenCbAuth && tokenAuth && user);
    const { pathname } = useRouter();
    const isRetailCustomerFaceRoute = pathname.includes(getKeyFromRoute(getRoute(ROUTES.retailOtc.customerFace)));
    const [, setCustomerFaceBetSlip] = useLocalStorage(CustomerFaceLocalStorageKey.BetSlip, initialCustomerFaceState);
    const [, setCustomerFaceItemsToPurchase] = useLocalStorage<ItemToPurchase[]>(
        CustomerFaceLocalStorageKey.ItemsToPurchase,
        [],
    );
    const [, setCustomerFaceRoute] = useLocalStorage(CustomerFaceLocalStorageKey.Route, CustomerFaceRoute.Banner);

    useEffect(() => {
        initLocalStorageListenerForKeycloakTokens();
        if (tokens.token) {
            setIsLoading(true);
            emitKeycloakTokens(tokens);
        }
    }, []);

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (currentInput) {
            setCredentials({
                ...credentials,
                [currentInput]: inputValue,
            });
        }
    }, [inputValue]);

    useEffect(() => {
        setCustomerFaceBetSlip(initialCustomerFaceState);
        setCustomerFaceItemsToPurchase([]);
        setCustomerFaceRoute(CustomerFaceRoute.Banner);
    }, []);

    async function handleLogin() {
        setIsLoading(true);
        try {
            const {
                access_token: token,
                refresh_token: refreshToken,
                id_token: idToken,
            } = await loginUser(credentials);
            await init({
                token,
                refreshToken,
                idToken,
            });
        } catch (error) {
            logger.error('RetailOtcLogin', 'handleLogin', error);
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return (
            <Wrapper>
                <Spin size="large" />
            </Wrapper>
        );
    }

    if (isRetailCustomerFaceRoute && !isAuthenticated) {
        return <RetailOtcCustomerFaceBanner />;
    }

    return (
        <Wrapper>
            <Row justify="center">
                <h1>{CLIENT_NAME.toUpperCase()}</h1>
            </Row>
            <Row gutter={16} justify="center" align="middle">
                <Col span={12}>
                    <Space direction="vertical" className="form" size="small">
                        <RetailOtcTouchInput
                            name="username"
                            value={credentials.username}
                            readOnly
                            onChange={e =>
                                setCredentials({
                                    ...credentials,
                                    username: e.target.value,
                                })
                            }
                            onFocus={e => {
                                setInputValue(credentials.username);
                                setCurrentInput(e.target.name);
                            }}
                            className="form-input"
                            autoFocus
                            spellCheck={false}
                            autoComplete="off"
                            placeholder="Username"
                        />
                        <RetailOtcTouchInput
                            name="password"
                            value={credentials.password}
                            readOnly
                            onChange={e =>
                                setCredentials({
                                    ...credentials,
                                    password: e.target.value,
                                })
                            }
                            onFocus={e => {
                                setInputValue(credentials.password);
                                setCurrentInput(e.target.name);
                            }}
                            className="form-input"
                            spellCheck={false}
                            autoComplete="off"
                            placeholder="Password"
                            type="password"
                        />
                        {/* eslint-disable-next-line rulesdir/forbid-jsx-component */}
                        <Button
                            className="login-btn"
                            block
                            type="primary"
                            onClick={handleLogin}
                            disabled={Object.values(credentials).some(value => value.length === 0) || isLoading}
                        >
                            LOGIN
                        </Button>
                    </Space>
                </Col>
                <Col span={8} className="keyboard">
                    <UiKeyboard
                        setValue={setInputValue}
                        width="100%"
                        layout={{ default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'] }}
                        display={{ '{bksp}': '⌫' }}
                    />
                </Col>
            </Row>
        </Wrapper>
    );
}

const color = {
    gray50: '#161922',
    gray100: '#292B35',
    gray200: '#383A47',
    gray300: '#454858',
    gray400: '#58596A',
    gray500: '#6F707F',
    gray600: '#898A96',
    gray700: '#ABACB4',
    gray800: '#C8C8CD',
    gray900: '#DDDDE0',
    gray1000: '#F2F2F3',

    blue100: '#1C232C',
    blue200: '#19344D',
    blue300: '#134E86',
    blue400: '#1969B3',
    blue500: '#2595E7',
    blue600: '#62A8E9',
    blue700: '#79B4EC',
    blue800: '#A5CDF3',
    blue900: '#D2E6F9',
    blue1000: '#E9F3FC',

    cyan500: '#02CFD0',
    cyan600: '#13A8A8',

    gold100: '#251C01',
    gold200: '#493803',
    gold300: '#7B5D05',
    gold400: '#A87F06',
    gold500: '#C59507',
    gold600: '#DCB53F',
    gold700: '#F3CC62',
    gold800: '#F8DF8B',
    gold900: '#FCF3CF',
    gold1000: '#FBF8EF',

    orange100: '#231404',
    orange200: '#452708',
    orange300: '#72420D',
    orange400: '#945512',
    orange500: '#D87A16',
    orange600: '#EA9C48',
    orange700: '#EFB576',
    orange800: '#F4CDA4',
    orange900: '#FAE6D1',
    orange1000: '#FCF3E8',

    geekblue3: '#1C2755',
    geekblue5: '#234092',
    geekblue6: '#2B4ACB',
    geekblue9: '#A8C1F8',

    green100: '#0D1B0C',
    green200: '#1B3517',
    green300: '#2D5927',
    green400: '#407C37',
    green500: '#498E3E',
    green600: '#7CC171',
    green700: '#9DD095',
    green800: '#BDE0B8',
    green900: '#DEEFDC',
    green1000: '#F9FCF8',

    goldenPurple6: '#642AB5',

    lime100: '#20231A',
    lime300: '#505B15',
    lime600: '#8BBB11',
    lime800: '#BDE0B8',

    magenta5: '#AE2D74',
    magenta6: '#CB2B83',

    red100: '#2A0C09',
    red200: '#541812',
    red300: '#7E251B',
    red400: '#A93123',
    red500: '#C94C3F',
    red600: '#F78778',
    red700: '#F4A99F',
    red800: '#F1CAC6',
    red900: '#F7E5E3',
    red1000: '#FCF8F7',

    volcano6: '#D84A1B',
    volcano100: '#210F06',
    volcano200: '#411E0C',
    volcano300: '#6C3113',
    volcano400: '#98441A',
    volcano500: '#CE5E27',
    volcano600: '#E18151',
    volcano700: '#E8A17D',
    volcano800: '#F0C0A8',
    volcano900: '#F7E0D4',
    volcano1000: '#FBEFE9',

    yellow600: '#F9DD39',

    white: '#FFFFFF',

    warning: '#ECA52B',
    success: '#28A745',
};

const sizes = {
    borderRadius: '0.3em',
    padding: '24px',
};

module.exports = {
    color,
    sizes,
};

import { Carousel } from 'antd';
import { useCmsSettingSync } from 'hooks/useCmsSettingSync';
import useLocalStorage from 'hooks/useLocalStorage';
import { BannerObject, Cms } from 'types/cms';
import { useEffect, useState } from 'react';
import Wrapper from './styles';
import { getStoreValue, store } from 'stores/store';
import { ClientName } from 'types/client';
import { getCmsParsedSettings } from '../../../../services/cms';

export function RetailOtcCustomerFaceBanner() {
    const DEFAULT_SPEED = 7_000;
    const [speed, setSpeed] = useState<number>(DEFAULT_SPEED);
    const [banners, setBanners] = useState<BannerObject[]>([]);
    const [betShopId] = useLocalStorage('BETSHOP_ID', '');
    const { CLIENT_NAME } = getStoreValue(store.environment);

    useEffect(() => {
        getCustomerFacingBanner();
    }, [betShopId]);

    useCmsSettingSync(`retail-banner-set-${betShopId}`, handleSetBanner);

    async function getCustomerFacingBanner() {
        if (!betShopId) return;
        const cms = (await getCmsParsedSettings(undefined, [`retail-banner-set-${betShopId}`])) as Cms[];
        const banners = cms?.[0]?.banners || [];
        setBanners(banners);
    }

    function handleSetBanner(record?: Cms[]) {
        setBanners(record?.[0].banners || []);
    }

    function handleAfterChange(slide) {
        if (!banners.length) {
            setSpeed(DEFAULT_SPEED);
            return;
        }
        const interval = banners[slide]?.time_interval;
        const timeInterval = interval ? Number(interval) * 1_000 : DEFAULT_SPEED;
        setSpeed(timeInterval);
    }

    function getLogo(clientName: ClientName) {
        switch (clientName) {
            case ClientName.IVC:
                return '/assets/images/logos/island.png';
            case ClientName.STATION:
                return '/assets/images/logos/station.png';
            case ClientName.WYNNBET:
            case ClientName.WYNNBET_CORE:
            case ClientName.WYNNBET_MA:
            case ClientName.WYNNBET_WV:
            case ClientName.WYNNBET_NV:
                return '/assets/images/logos/wynnbet.png';
            default:
                return '/assets/images/logos/gan-sports.png';
        }
    }

    if (!banners.length) {
        return (
            <Wrapper>
                <div className="default-logo">
                    <img src={getLogo(CLIENT_NAME)} alt="client-logo" />
                </div>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Carousel
                afterChange={handleAfterChange}
                autoplaySpeed={speed}
                effect="fade"
                autoplay
                infinite
                adaptiveHeight
                dots={false}
            >
                {banners.map((banner, key) => {
                    return (
                        <div key={key}>
                            <img src={banner.media?.image} alt={banner.media?.title} className="customer-image" />
                        </div>
                    );
                })}
            </Carousel>
        </Wrapper>
    );
}

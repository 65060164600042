import { Input, InputProps, InputRef } from 'antd';
import React, { useRef } from 'react';
import Wrapper from './styles';

interface Props extends InputProps {
    isModalInput?: boolean;
}

export function RetailOtcTouchInput({ isModalInput = false, ...props }: Props) {
    const inputRef = useRef<InputRef>(null);

    function inputWrapperHandler() {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    return (
        <Wrapper isModalInput={isModalInput}>
            <div onClick={inputWrapperHandler} className="input-wrapper">
                <Input ref={inputRef} readOnly {...props} className={`touch-input ${props.className}`} />
            </div>
        </Wrapper>
    );
}

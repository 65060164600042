import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Input, InputRef, List, Row, Tooltip } from 'antd';
import { getCategoryById, getCategoryNamesByNameOrIds } from 'microservices/sports/category';
import { searchMatch, SearchMatchByNameBo } from 'microservices/sports/match';
import { useDebounce } from 'hooks/useDebounce';
import { getRoute, useRouter } from 'services/router';
import { displayCategoryPath } from 'services/category';
import { ROUTES } from 'routes';
import { CategoryNames } from '@staycool/sports-types/dist/features/category/types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { store, useStore } from 'stores/store';
import { dateFormat } from '../../../services/date';
import { MatchStatus } from '@staycool/sports-types';
import { categoryStore } from '../../../stores/matchPage';
import { Category } from '@staycool/sports-types/dist/features/category/types';
import { kioskCmsRoutes, KioskRouteType } from '../../../services/cms';

const { Search } = Input;

interface Props {
    closeModal?: () => void;
    useSearchMatch?: boolean;
    useCategoryMatch?: boolean;
    onClickCategory?: (category: CategoryNames) => void;
    onClickMatch?: (match: SearchMatchByNameBo) => void;
    onClickRoute?: (route: KioskRouteType) => void;
    categoryDepth?: number;
    sportId?: number;
    showSelectedText?: boolean;
    showHintIcon?: boolean;
    size?: 'small' | 'middle' | 'large';
    isDisabled?: boolean;
    resetOptionsOnSave?: boolean;
    resetOptionsOnValueChange?: any;
    matchStatuses?: MatchStatus[];
    placeholder?: string;
    value?: string | null;
    includeRetailRoutes?: boolean;
}

export function SportsbookCategoryAndMatchSearch({
    closeModal = () => {},
    useSearchMatch = true,
    useCategoryMatch = true,
    onClickCategory,
    categoryDepth,
    sportId,
    showSelectedText,
    onClickMatch,
    showHintIcon = true,
    size = 'large',
    isDisabled = false,
    resetOptionsOnSave = true,
    resetOptionsOnValueChange,
    matchStatuses,
    placeholder = 'Search...',
    value,
    includeRetailRoutes,
    onClickRoute,
}: Props) {
    const [isCategoryAndMatchSearchModalVisible] = useStore(store.sportsbook.isCategoryAndMatchSearchModalVisible);
    const inputRef = useRef<InputRef>(null);
    const { navigateTo } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(value ?? '');

    const [categoryOptions, setCategoryOptions] = useState([] as CategoryNames[]);
    const [visibleCategoryOptions, setVisibleCategoryOptions] = useState([] as CategoryNames[]);
    const [isAllCategoryOptionsVisible, setIsAllCategoryOptionsVisible] = useState(false);

    const [matchOptions, setMatchOptions] = useState([] as SearchMatchByNameBo[]);
    const [visibleMatchOptions, setVisibleMatchOptions] = useState([] as SearchMatchByNameBo[]);
    const [areAllMatchOptionsVisible, setAreAllMatchOptionsVisible] = useState(false);
    const [category, setCategory] = useStore(categoryStore);
    const [retailRoutesOptions, setRetailRoutesOptions] = useState([] as KioskRouteType[]);

    const delayedSearch = useDebounce(loadCategoriesAndMatches, 300);

    useEffect(() => {
        if (isCategoryAndMatchSearchModalVisible) {
            setTimeout(
                () =>
                    inputRef.current?.focus({
                        cursor: 'all',
                    }),
                100,
            );
        }
    }, [isCategoryAndMatchSearchModalVisible]);

    useEffect(() => {
        setSearchTerm(value ?? '');
    }, [value]);

    useEffect(() => {
        if (resetOptionsOnValueChange) {
            setSearchTerm('');
            resetOptions();
        }
    }, [resetOptionsOnValueChange]);

    function resetOptions() {
        setCategoryOptions([]);
        setVisibleCategoryOptions([]);
        setMatchOptions([]);
        setVisibleMatchOptions([]);
    }

    function applyResetOptionsOnSave() {
        if (!resetOptionsOnSave) {
            return;
        }

        resetOptions();
    }

    async function handleSearch(e: React.FormEvent<HTMLInputElement>) {
        const searchTerm = e.currentTarget.value;
        setSearchTerm(searchTerm);
        delayedSearch(searchTerm.trim());
    }

    async function loadCategoriesAndMatches(searchTerm: string) {
        if (!searchTerm || searchTerm.length < 3) {
            resetOptions();
            return;
        }

        setIsLoading(true);
        const [foundCategories, foundMatches] = await Promise.all([
            useCategoryMatch ? getCategoryNamesByNameOrIds({ name: searchTerm, depth: categoryDepth, sportId }) : [],
            useSearchMatch ? searchMatch({ search: searchTerm, statuses: matchStatuses }) : [],
        ]);
        if (includeRetailRoutes) {
            setRetailRoutesOptions(
                kioskCmsRoutes.filter(route => route.name.toLowerCase().includes(searchTerm.toLowerCase())),
            );
        }
        setCategoryOptions(foundCategories);
        setVisibleCategoryOptions(foundCategories.slice(0, 5));
        setMatchOptions(foundMatches);
        setVisibleMatchOptions(foundMatches.slice(0, 10));
        setIsLoading(false);
    }

    function handleCategorySelect(categoryId: number, category: CategoryNames) {
        if (onClickCategory) {
            onClickCategory(category);
            applyResetOptionsOnSave();
            if (showSelectedText) {
                setSearchTerm(category.name);
            }
            return;
        }

        store.sportsbook.treeSelectedCategory.set({ id: categoryId, name: category.name });
        navigateTo(`${getRoute(ROUTES.sportsbook.categories)}/${categoryId}/matches?updateCategoryTree=true`);
        setSearchTerm('');
        applyResetOptionsOnSave();
        closeModal();
    }

    function handleRouteOptionsSelect(routeOptions: KioskRouteType) {
        onClickRoute?.(routeOptions);
        applyResetOptionsOnSave();
        if (showSelectedText) {
            setSearchTerm(category.name);
        }
        return;
    }

    async function handleMatchSelect(matchId: number, categoryId: number, match: SearchMatchByNameBo) {
        if (onClickMatch) {
            onClickMatch(match);
            applyResetOptionsOnSave();
            return;
        }

        if (categoryId !== category.id) {
            const fetchedCategory = await getCategoryById(categoryId);
            setCategory(fetchedCategory as Category);
        }

        navigateTo(`${getRoute(ROUTES.sportsbook.categories)}/${categoryId}/matches/${matchId}`);
        setSearchTerm('');
        closeModal();
    }

    return (
        <div>
            <Search
                size={size}
                ref={inputRef}
                value={searchTerm}
                onChange={handleSearch}
                placeholder={placeholder}
                loading={isLoading}
                allowClear
                disabled={isDisabled}
                suffix={
                    showHintIcon && (
                        <Tooltip title="Press ctrl + shift + f to auto select">
                            <InfoCircleOutlined />
                        </Tooltip>
                    )
                }
            />
            {retailRoutesOptions.length > 0 && (
                <>
                    <Divider orientation="left">
                        Found {retailRoutesOptions.length}
                        {retailRoutesOptions.length > 1 ? 'Kiosk routes' : 'Kiosk route'}
                    </Divider>
                    <List
                        size="small"
                        bordered
                        dataSource={retailRoutesOptions}
                        renderItem={routeOption => (
                            <List.Item onClick={() => handleRouteOptionsSelect(routeOption)} className="list-item">
                                <Row>{routeOption.name}</Row>
                            </List.Item>
                        )}
                    />
                </>
            )}
            {visibleCategoryOptions.length > 0 && (
                <>
                    <Divider orientation="left">
                        Found {categoryOptions.length} {categoryOptions.length > 1 ? 'categories' : 'category'}{' '}
                        {visibleCategoryOptions < categoryOptions && (
                            // eslint-disable-next-line rulesdir/forbid-jsx-component
                            <Button
                                size="small"
                                onClick={() => setIsAllCategoryOptionsVisible(!isAllCategoryOptionsVisible)}
                            >
                                {isAllCategoryOptionsVisible ? 'Show Less' : 'Show All'}
                            </Button>
                        )}
                    </Divider>
                    <List
                        size="small"
                        bordered
                        dataSource={isAllCategoryOptionsVisible ? categoryOptions : visibleCategoryOptions}
                        renderItem={category => (
                            <List.Item
                                onClick={() => handleCategorySelect(category.id, category)}
                                className="list-item"
                            >
                                <Row>{displayCategoryPath(category)}</Row>
                            </List.Item>
                        )}
                    />
                </>
            )}
            {visibleMatchOptions.length > 0 && (
                <>
                    <Divider orientation="left">
                        Found {matchOptions.length} {matchOptions.length > 1 ? 'matches' : 'match'}{' '}
                        {visibleMatchOptions < matchOptions && (
                            // eslint-disable-next-line rulesdir/forbid-jsx-component
                            <Button
                                size="small"
                                onClick={() => setAreAllMatchOptionsVisible(!areAllMatchOptionsVisible)}
                            >
                                {areAllMatchOptionsVisible ? 'Show Less' : 'Show All'}
                            </Button>
                        )}
                    </Divider>
                    <List
                        itemLayout="vertical"
                        size="small"
                        bordered
                        dataSource={areAllMatchOptionsVisible ? matchOptions : visibleMatchOptions}
                        renderItem={match => (
                            <List.Item
                                onClick={async () => await handleMatchSelect(match.id, match.category_id, match)}
                                className="list-item"
                            >
                                <Row>{match.name}</Row>
                                <Row>{`${dateFormat(match.match_start, 'seconds')} - ${match.status} - ${
                                    match.leagueName
                                }`}</Row>
                            </List.Item>
                        )}
                    />
                </>
            )}
        </div>
    );
}

import { SupportedLanguages } from '@staycool/config/dist/types';
import { Country, Market } from '@staycool/location';
import { FoCategoryMarketType } from '@staycool/sbgate-types';
import { AnyObject, Nullable, TNode } from '@udecode/plate-core';
import { DefaultOptionType } from 'antd/es/select';
import { RcFile } from 'antd/lib/upload';
import { BoardView } from 'microservices/digital-board';
import { ObjectValues } from './util';

export type TextByLanguage = Partial<Record<SupportedLanguages, string>>;

export interface InfoListItem {
    message: string;
    secondary?: string;
    type?: 'info' | 'error' | 'warning';
}

export interface BannerObject {
    id?: number;
    position: number;
    media?: {
        title: string;
        image: string;
    };
    content: string;
    link: string;
    time_interval: string;
}

export interface HotCard {
    id?: number;
    position: number;
    media?: {
        title: string;
        image: string;
    };
    event?: HotCardEventObject;
    title: string;
    content: string;
    categoryName?: string;
    isShowOdds: boolean;
    marketTypeId?: number;
}

export interface RulesPage {
    htmlString: string;
    slateData: TNode<AnyObject>[];
}

export interface OddsSheet {
    image?: {
        title: string;
        url: string;
    };
    pageSize?: string;
    matchIds?: number[];
    headerText?: TNode<AnyObject>[];
    footerText?: TNode<AnyObject>[];
    categories?: OddsSheetCategory[];
    filterDate?: {
        timeFrom?: Date;
        timeTo?: Date;
    };
}

export interface ApplicationSheet {
    headerImage?: {
        title: string;
        url: string;
    };
    logoImage?: {
        title: string;
        url: string;
    };
    titleText?: string;
    descriptionText?: string;
}

interface RegistrationImagesType {
    title: string;
    url: string;
}

export interface RegistrationImages {
    emailImage?: RegistrationImagesType;
    passwordImage?: RegistrationImagesType;
    otpImage?: RegistrationImagesType;
    ssnImage?: RegistrationImagesType;
    loyaltyImage?: RegistrationImagesType;
    tncImage?: RegistrationImagesType;
    camsImage?: RegistrationImagesType;
    migrateCreateNewImage?: RegistrationImagesType;
}

export interface OddsSheetCategory {
    id: number;
    name: string;
}

export interface HotCardEventObject {
    id: number;
    name: string;
    sportId?: number;
    categoryId?: number;
    hotCardType: HotCardType;
    parentName?: string;
}

export enum CmsInputType {
    Input = 'input',
    Dropdown = 'dropdown',
    Media = 'media',
    Checkbox = 'checkbox',
    Market = 'market',
}

export interface MediaObject {
    bucket?: string;
    category: string;
    deleted?: boolean;
    filename?: string;
    hidden?: boolean;
    id: string;
    real_file_type?: string;
    size?: number;
    title: string;
    updated?: string;
    url: string;
}

export interface MaintenanceImageObject {
    id: string;
    media?: {
        title: string;
        image: string;
    };
}

export type BannerSetObject = Omit<BannerObject, 'content' | 'link'>;

export enum HotCardType {
    LeagueHotCard = 'LeagueHotCard',
    MatchHotCard = 'MatchHotCard',
    RouteHotCard = 'RouteHotCard',
}

export interface MarketOptions {
    [key: number]: FoCategoryMarketType[];
}

export enum MediaType {
    Modal = 'Modal',
    Fullscreen = 'FullScreen',
}

export interface CmsData {
    name: string;
    position?: string;
}

export interface BetshopData {
    name: string;
    type: string;
    id: string;
    position?: string;
    banners?: BannerObject[] | BannerSetObject[];
    hotCards?: HotCard[];
    rulesPage?: RulesPage;
    oddsSheet?: OddsSheet;
    applicationSheet?: ApplicationSheet;
    registrationImages?: RegistrationImages;
    maintenanceImage?: MaintenanceImageObject;
}

export interface CmsRetailAction {
    betshopsIds: number[] | string[];
    type: CmsType;
    data: CmsRetailActionData;
    name: string;
    action: 'create' | 'update';
    position?: string;
    cmsId?: Cms['id'];
}

export type CmsRetailActionData =
    | BannerObject[]
    | HotCard[]
    | RulesPage
    | OddsSheet
    | BannerSetObject[]
    | ApplicationSheet
    | RegistrationImages;

export enum CmsSettingType {
    RetailTopBanner = 'retail-top-banner',
    RetailBottomBanner = 'retail-bottom-banner',
    RetailHotEventCards = 'retail-hot-event-cards',
    RetailRulesPage = 'retail-rules-page',
    OddsSheet = 'retail-odds-sheet',
    ApplicationSheet = 'retail-application-sheet',
    RetailBannerSet = 'retail-banner-set',
    RegistrationImages = 'retail-registration-images',
    MaintenanceImage = 'retail-maintenance-image',
}

export interface CmsUpdate {
    cmsBetshops: string[];
    selectedBetshops: string[];
    name: string;
    position?: string;
    banners?: BannerObject[] | BannerSetObject[];
    maintenanceImage?: MaintenanceImageObject;
    betshops: { id: string; name: string }[];
    data: any;
    cms: Cms;
    type: CmsType;
    skipValidation?: boolean;
    existingBetshops?: string[];
}

export type CmsCreate = Omit<CmsUpdate, 'cmsBetshops' | 'cms'>;

export interface CmsValidatorInput {
    name: string;
    newBetshops: string[];
    position?: string;
    type: CmsType;
    banners?: BannerObject[] | BannerSetObject[];
    betshops: { id: string; name: string }[];
    validate?: boolean;
}

export type ExistingBetshopValidator = Pick<CmsValidatorInput, 'type' | 'newBetshops' | 'betshops' | 'position'>;

export const CMS_SETTINGS_TYPE = {
    COUNTRY_SINGLE_IMAGE: 'country-single-image',
    COUNTRY_MULTIPLE_IMAGE: 'country-multiple-images',
    LICENSE_BANNER: 'license-banner',
} as const;

export type CmsSettingsType = ObjectValues<typeof CMS_SETTINGS_TYPE>;

export const CMS_FO_SCREENS_FOR_BANNERS = {
    MAIN_PAGE: 'main_page',
    RESPONSIBLE_GAMING: 'responsible_gaming',
    SELF_EXCLUSION: 'self_exclusion',
    FAQ: 'faq',
    TERMS_AND_CONDITIONS: 'terms_and_conditions',
    PLAYER_AGREEMENT: 'player_agreement',
    CUSTOMER_CARE: 'customer_care',
} as const;

export type CmsFoScreensForBanners = ObjectValues<typeof CMS_FO_SCREENS_FOR_BANNERS>;

export interface CmsSettings {
    createdAt: string;
    key: string;
    type: CmsSettingsType;
    updatedAt: Date;
    updatedBy: number;
    value: string;
}

export interface CmsGeneralSettingsType extends CmsSettings {
    label: string;
}

export type CmsSettingsCountrySingleImageValue = {
    [key in Country]: string | undefined;
};

export interface CmsSettingsCountryMultipleImageValueType {
    imageRedirectLink?: string;
    imageURL: string;
}

export type CmsSettingsCountryMultipleImageValue = {
    [key in Country]: CmsSettingsCountryMultipleImageValueType[];
};

export interface CmsSettingsLicenseBannerValueType {
    image?: string;
    imageRedirectLink?: string;
    screens: string[];
    translationKey: string;
}

export type CmsSettingsLicenseBannerValue = {
    [key in Country]: CmsSettingsLicenseBannerValueType;
};

export type CmsGeneralSettingsForm = Record<
    string,
    CmsSettingsCountrySingleImageValue | CmsSettingsCountryMultipleImageValue | CmsSettingsLicenseBannerValue
>;

export interface CmsBody {
    key: string;
    value: any;
    type?: string;
}

export interface Cms {
    id: string | number;
    name: string;
    type: CmsType;
    updatedBy?: number;
    updatedAt: Date;
    banners?: BannerObject[];
    hotCards?: HotCard[];
    rulesPage?: RulesPage;
    oddsSheet?: OddsSheet;
    applicationSheet?: ApplicationSheet;
    registrationImages?: RegistrationImages;
    maintenanceImage?: MaintenanceImageObject;
    position?: string;
    betshops?: string[];
    used_on?: number;
    views?: BoardView[];
}

export enum CmsType {
    HotCard = 'Hot Card',
    Banner = 'Banner',
    RulesPage = 'Rules Page',
    OddsSheet = 'Odds Sheet',
    DigitalBoards = 'Digital Boards',
    OddsView = 'Odds View',
    BannerSet = 'Banner Set',
    ApplicationSheet = 'Application Sheet',
    RegistrationImages = 'Registration Images',
    MaintenanceImage = 'Maintenance Image',
}

export interface CmsContentFilterBase {
    searchText?: string;
    pageNumber: number;
    itemsPerPage: number;
    sortKey?: string;
    sortDirection?: 'asc' | 'desc';
}

export interface CmsFilterResultBase {
    filter: any;
    summary: {
        totalFound?: number;
        pageNumber?: number;
        pages?: number;
        itemsReturned?: number;
    };
    items: any[];
}

export enum CmsSnippetResponseCodes {
    SNIPPET_ALREADY_EXISTS = 'cms-409-4093',
}

export interface TranslationSuggestedRedirectKey {
    key: string;
    redirect_key_id: null;
}

export interface CmsTranslation {
    id: number;
    key: string;
    redirect_key_id: number | null;
    text: Record<string, string>;
    created_at?: Date;
    updated_at?: Date;
}

export interface CmsTranslationHistory {
    id: number;
    translation_id: number;
    language: string;
    previous_value: string;
    new_value: string;
    updated_by: string;
    updated_at: Date;
}

export interface MissingTranslation {
    key: string;
    count?: number;
    fallback?: string;
    location?: string;
    path?: string;
    language: string;
    imported_at: Date;
    created_at: Date;
    updated_at: Date;
}

export interface ErrorResponse extends Error {
    code: string;
}
export enum CmsErrorCode {
    BAD_REQUEST = 4000,
    BAD_REQUEST_DUPLICATE = 4001,
}

export interface CmsCategory {
    added_by?: string | number;
    created?: Date;
    id?: string;
    name: string;
    published: boolean;
    translation_key?: string;
    type: 'blog' | 'email' | 'page' | 'snippet';
    updated?: Date;
}

export type UserDevice = 'mobile' | 'tablet' | 'desktop';

export interface BlogPostMarket {
    post_id: number;
    market: Market;
}

export const MEDIA_CMS_BUCKET = {
    CASINO: 'casino',
    CMS: 'cms',
    COMMON: 'common',
    PAYMENTS: 'payments',
    RESPONSIBLE_GAMING: 'responsible-gaming',
} as const;

export type MediaCmsBucket = ObjectValues<typeof MEDIA_CMS_BUCKET>;

export const MEDIA_CMS_DEPARTMENTS = [
    'Marketing',
    'Casino',
    'Sportsbook',
    'Payments',
    'Services',
    'Responsible gaming',
    'Others',
] as const;

export type MediaCmsDepartment = typeof MEDIA_CMS_DEPARTMENTS[number];

export interface MediaCmsResponse {
    data: MediaCms[];
    pagination: {
        limit: number;
        offset: number;
        total: number;
    };
}

export interface MediaCms {
    id: string;
    bucket: MediaCmsBucket;
    category: string;
    created_at: string;
    created_by_bo_subject: string;
    department: MediaCmsDepartment | null;
    fileName: string;
    folder_id: number | null;
    height: number | null;
    hidden: boolean;
    real_file_type: string;
    size: number;
    tags: string[] | null;
    title: string;
    updated_at: string;
    updated_by: number;
    updated_by_bo_subject: string | null;
    url: string;
    width: number | null;
    is_old_media: boolean;
}

export interface MediaCmsFilter extends Partial<Pick<MediaCms, 'department' | 'folder_id'>> {
    limit: number;
    offset: number;
    searchText?: string;
    categories: string[];
    tags: NonNullable<MediaCms['tags']>;
    orderBy?: string;
    is_old_media?: Nullable<MediaCms['is_old_media']>;
}

export interface MediaCmsUpload
    extends Pick<MediaCms, 'bucket' | 'category' | 'title'>,
        Partial<Pick<MediaCms, 'hidden' | 'tags' | 'folder_id'>> {
    image: RcFile;
    department: NonNullable<MediaCms['department']>;
    height: NonNullable<MediaCms['height']>;
    width: NonNullable<MediaCms['width']>;
}

export interface MediaCmsUpdate extends Pick<MediaCms, 'id' | 'title'>, Partial<Pick<MediaCms, 'tags' | 'folder_id'>> {
    department: NonNullable<MediaCms['department']>;
}

export interface MediaCmsFilterOptions {
    categories: DefaultOptionType[];
    departments: DefaultOptionType[];
    tags: DefaultOptionType[];
}

export const MEDIA_CMS_TABLE_COLUMNS = {
    IMAGE: 'Image',
    NAME: 'Name',
    FORMAT: 'Format',
    SIZE: 'Size',
    DEPARTMENT: 'Department',
    CATEGORY: 'Category',
    TAGS: 'Tags',
    LAST_EDITED: 'Last edited',
    ACTIONS: 'Actions',
} as const;

export type MediaCmsTableColumns = ObjectValues<typeof MEDIA_CMS_TABLE_COLUMNS>;

export const MEDIA_CMS_TABLE_COLUMNS_ARRAY = Object.values(MEDIA_CMS_TABLE_COLUMNS);

export interface AvatarMedia {
    id: string;
    url: string;
    title: string;
}
